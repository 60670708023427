import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1.5,
  maxHeight: '75vh',
  overflow: 'scroll',
};

const BaseModal = ({
  open,
  setOpen,
  body,
}: {
  open: boolean;
  setOpen: Function;
  body: JSX.Element;
}) => {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{body}</Box>
      </Modal>
    </div>
  );
};

export default BaseModal;
