import { Datagrid, DateField, List, TextField } from 'react-admin';
import { Button, IconButton } from '@mui/material';
import { Campaign, Delete } from '@mui/icons-material';

const Packing = () => {
  const NotificationButtonField = () => {
    return (
      <Button
        className="datagrid-button"
        variant="outlined"
        startIcon={<Campaign />}
      >
        Dar aviso
      </Button>
    );
  };

  const OrdersField = ({ label }: { label: string }) => {
    return (
      <>
        Pedido
        <IconButton color="warning">
          <Delete />
        </IconButton>
      </>
    );
  };

  return (
    <List title="Packing" resource="packing/ingredients">
      <Datagrid bulkActionButtons={false}>
        <TextField label="Hora" source="id" />
        <DateField label="Origen y destino" source="order.deliver_date" />
        <OrdersField label="Pedidos" />
        <TextField label="Dar aviso" source="prepared_ingredient.name" />
        <NotificationButtonField />
      </Datagrid>
    </List>
  );
};

export default Packing;
