import { NumberInput } from 'react-admin';

type MoneyInputProps = {
  source?: string;
  confirmation?: boolean; // Sometimes we need this input next to other one to make the user confirm the money amount entered.
  // So when "confirmation" is true, we do not need a valid source, just use something like confirmation_amount source for the validation funcion to find the input and work.
  label?: string;
  options?: any;
  disabled?: boolean;
  required?: boolean;
  sx?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const MoneyInput = (props: MoneyInputProps) => {
  return (
    <>
      <span
        style={{
          position: 'relative',
          top: '1em',
          right: 2,
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        $
      </span>
      <NumberInput
        {...props}
        source={
          props.confirmation
            ? props.source
              ? props.source
              : 'reallyImprobableStringToBeTheSourceOfARecordAndCauseAnError'
            : props.source || 'foo'
        } // Note that foo is will never be used. But Typescript cannot know that and we need to catch all logical cases.
        options={{
          style: 'currency',
        }}
        required={props.required}
      />
    </>
  );
};

export default MoneyInput;
