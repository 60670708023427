import { useState } from 'react';
import {
  List,
  Datagrid,
  TextField as TextFieldRA,
  useEditContext,
  useRecordContext,
  useRefresh,
  useUpdate,
  useDelete,
  Identifier,
} from 'react-admin';
import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import BaseModal from '../../../components/BaseModal';
import AutocompleteCustom from '../../../components/AutocompleteCustom';
import { simpleFetch } from '../../../utils/fetch';

const ModalBody = ({ closeModal }: { closeModal: () => void }) => {
  const { record } = useEditContext();
  const [selectedIngredient, setSelectedIngredient] = useState<any>(null);
  const [orderQuantity, setOrderQuantity] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const createOhpi = async () => {
    const newOhpi = {
      order: record.id,
      prepared_ingredient: selectedIngredient.id,
      ordered_quantity: orderQuantity,
      is_pending: isPending,
    };
    setLoading(true);
    const response = await simpleFetch({
      url: `packing/ingredients`,
      method: 'POST',
      body: newOhpi,
    });
    if (response.ok) {
      closeModal();
    } else {
      setError(
        'No se pudo agregar el ingrediente, revise los campos ingresados'
      );
    }
    setLoading(false);
  };

  return (
    <Grid item sx={{ width: { md: '50vw', sx: '100%' } }}>
      <Typography variant="h5">{`Nuevo ingrediente:`}</Typography>
      <Box sx={{ display: 'flex', flexDirection: { md: 'row', sx: 'column' } }}>
        <AutocompleteCustom
          label="Ingredientes"
          source="prepared_ingredients"
          onChange={(ingredient) => setSelectedIngredient(ingredient)}
        />
        {selectedIngredient && (
          <>
            <Box sx={{ margin: { md: '0 2rem', sx: '1rem 0' } }}>
              <TextField
                label="Cantidad"
                type="number"
                value={orderQuantity}
                onChange={(event) => {
                  if (event.target.value) {
                    setOrderQuantity(Number(event.target.value));
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: selectedIngredient.metric.step,
                }}
              />
            </Box>
            <FormControlLabel
              control={<Checkbox value={isPending} />}
              onChange={(_, checked) => setIsPending(checked)}
              label="Pendiente"
            />
          </>
        )}
      </Box>
      {error && (
        <Typography variant="body1" color={'error'}>
          {error}
        </Typography>
      )}
      {selectedIngredient && (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => createOhpi()}
          sx={{ marginTop: '1rem' }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={30} color="inherit" />
          ) : (
            'Agregar ingrediente'
          )}
        </Button>
      )}
    </Grid>
  );
};

const DeleteIngredientModalBody = ({
  id,
  name,
  closeModal,
}: {
  id: Identifier;
  name: string;
  closeModal: () => void;
}) => {
  const refresh = useRefresh();
  const [deleteOne, { isLoading }] = useDelete(
    `packing/ingredients`,
    {
      id: id,
    },
    {
      onSuccess: () => {
        refresh();
        closeModal();
      },
    }
  );

  return (
    <Grid item>
      <Typography variant="h5">{`¿Deseas eliminar el ingrediente: ${name}?`}</Typography>
      <Box
        sx={{
          marginTop: '2rem',
          display: 'flex',
          justifyContent: { md: 'space-between', sx: '95%' },
        }}
      >
        <Button variant="outlined" color="info" onClick={() => closeModal()}>
          {'Salir'}
        </Button>
        <Button variant="outlined" color="error" onClick={() => deleteOne()}>
          {isLoading ? (
            <CircularProgress size={20} color="error" />
          ) : (
            'Confirmar'
          )}
        </Button>
      </Box>
    </Grid>
  );
};

const BooleanExternal = ({ label }: { label: string }) => {
  const record = useRecordContext();
  const [isPending, setIsPending] = useState<boolean>(record.pending);

  const [update, { isLoading }] = useUpdate(
    `packing/ingredients`,
    {
      id: record.id,
      data: {
        pending: !record.pending,
      },
      previousData: record,
    },
    {
      onSuccess: (data) => {
        setIsPending(data.pending);
      },
    }
  );

  return (
    <Grid item display={'flex'} alignItems={'center'}>
      <Typography color="black">
        {isPending ? 'Pendiente' : 'No pendiente'}
      </Typography>
      <Tooltip title="Actualizar">
        <IconButton onClick={() => update()} disabled={isLoading}>
          {isLoading ? <CircularProgress size={20} /> : <Autorenew />}
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const DeleteButton = ({
  openModal,
  setModalBody,
}: {
  openModal: () => void;
  setModalBody: (id: Identifier, name: string) => void;
}) => {
  const record = useRecordContext();
  return (
    <Button
      variant="outlined"
      color="error"
      onClick={() => {
        openModal();
        setModalBody(record.id, record.prepared_ingredient.name);
      }}
    >
      {'Eliminar'}
    </Button>
  );
};

const Ingredients = () => {
  const { record } = useEditContext();
  const [openDispatchModal, setOpenDispatchModal] = useState(false);
  const [dispatchModalBody, setDispatchModalBody] = useState(<></>);
  const [openDispatchDeleteModal, setOpenDispatchDeleteModal] = useState(false);
  const [dispatchModalDeleteBody, setDispatchModalDeleteBody] = useState(<></>);

  return (
    <>
      <BaseModal
        open={openDispatchModal}
        setOpen={setOpenDispatchModal}
        body={dispatchModalBody}
      />
      <BaseModal
        open={openDispatchDeleteModal}
        setOpen={setOpenDispatchDeleteModal}
        body={dispatchModalDeleteBody}
      />
      <Typography variant="h4" mb={3}>
        Ingredientes del pedido <b>{record.id}</b>
      </Typography>
      <List resource={`boxes/${record.id}/ingredients`}>
        <Datagrid bulkActionButtons={false}>
          <TextFieldRA label="Ingrediente" source="prepared_ingredient.name" />
          <TextFieldRA label="Cantidad" source="ordered_quantity" />
          <TextFieldRA label="Métrica" source="metric.name" />
          <BooleanExternal label="Estado" />
          <DeleteButton
            openModal={() => setOpenDispatchDeleteModal(true)}
            setModalBody={(id, name) =>
              setDispatchModalDeleteBody(
                <DeleteIngredientModalBody
                  id={id}
                  name={name}
                  closeModal={() => setOpenDispatchDeleteModal(false)}
                />
              )
            }
          />
        </Datagrid>
      </List>
      <Button
        variant="outlined"
        className="datagrid-button"
        sx={{ fontSize: '1.2rem' }}
        onClick={() => {
          setOpenDispatchModal(true);
          setDispatchModalBody(
            <ModalBody closeModal={() => setOpenDispatchModal(false)} />
          );
        }}
      >
        {'Agregar ingrediente'}
      </Button>
    </>
  );
};

export default Ingredients;
