import moment from 'moment';

export const moneyFormat = (amount: number | string) => {
  amount = Math.trunc(Number(amount));
  return '$' + parseFloat(amount.toString()).toLocaleString('es');
};

// Generates a color from a string. It's deterministic
export const generateColorFromString = (str: string, prc: number = 70) => {
  // Check for optional lightness/darkness

  // Generate a Hash for the String
  const hash = function (word: string) {
    var h = 0;
    for (var i = 0; i < word.length; i++) {
      h = word.charCodeAt(i) + ((h << 5) - h);
    }
    return h;
  };

  // Change the darkness or lightness
  const shade = function (color: string, prc: number) {
    var num = parseInt(color, 16),
      amt = Math.round(2.55 * prc),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;
    return (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1);
  };

  // Convert init to an RGBA
  const int_to_rgba = function (i: number) {
    var color =
      ((i >> 24) & 0xff).toString(16) +
      ((i >> 16) & 0xff).toString(16) +
      ((i >> 8) & 0xff).toString(16) +
      (i & 0xff).toString(16);
    return color;
  };

  return `#${shade(int_to_rgba(hash(str)), prc)}`;
};

// Get URL parameter
export const getURLParam = (name: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const resetFilters = () => {
  // This needs to be changed for working with generic filters and not only date filters in packing.
  // We gonna use two params for this function (lsKey, filtersArray)

  let filters = localStorage.getItem('RaStore.packing/ingredients.listParams');

  if (typeof filters === 'string') {
    let parse = JSON.parse(filters);
    parse.filter.start_date = moment().format('yyyy-MM-DD');
    parse.filter.end_date = moment().isoWeekday('Sunday').format('yyyy-MM-DD');
    localStorage.setItem(
      'RaStore.packing/ingredients.listParams',
      JSON.stringify(parse)
    );
  }
};

export const resetFiltersTest = (
  lsKey: string,
  filtersArray: Array<{ key: string; value: string }>
) => {
  let filters = localStorage.getItem(lsKey);

  if (typeof filters === 'string') {
    let parse = JSON.parse(filters);
    for (const f of filtersArray) {
      parse.filter[f.key] = f.value;
    }
    localStorage.setItem(lsKey, JSON.stringify(parse));
  }
};
