import { List, Datagrid, NumberField, TextField } from 'react-admin';

const Pickups = () => {
  return (
    <List resource="boxes" className="ra-padding-reset">
      <Datagrid bulkActionButtons={false}>
        <NumberField sx={{ fontWeight: 'bold' }} label="Pedido" source="id" />
        <TextField label="Fecha" source="max_deliver_time" />
        <NumberField label="Hora" source="id" />
        <NumberField label="Rango" source="id" />
        <NumberField label="Comuna" source="id" />
        <NumberField label="Dirección" source="id" />
        <NumberField label="Estado" source="id" />
        <NumberField label="Despacho" source="id" />
      </Datagrid>
    </List>
  );
};

export default Pickups;
