import { useSidebarState } from 'react-admin';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  Avatar,
  useMediaQuery,
} from '@mui/material';
import ReactTourProvider from '../../components/ReactTourProvider';

const MAX_LENGTH = 15;
const OutletConfiguration = ({
  userOutlet,
  showTour,
}: {
  userOutlet: any;
  showTour: Boolean;
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [, setOpen] = useSidebarState();

  const outletBasicData =
    userOutlet.contact_phone &&
    userOutlet.address &&
    userOutlet.contact_phone !== 'noingresado' &&
    userOutlet.address !== 'noingresado' &&
    userOutlet.product_count > 0;

  const FirstSection = () => {
    if (isMobile) {
      return (
        <>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center' }}
            className="second-step"
          >
            <Avatar
              sx={{
                width: '4rem',
                height: '4rem',
                marginRight: '1rem',
                bgcolor: 'primary.main',
              }}
            >
              <Typography variant="h2" mt="12px">
                {userOutlet.name ? userOutlet.name[0].toUpperCase() : 'C'}
              </Typography>
            </Avatar>
            <Typography
              variant="h4"
              noWrap
              display={'inline-block'}
              width="100% "
            >
              Sitio web:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              noWrap
              display={'inline-block'}
              width="100% "
            >
              <Link
                href={userOutlet.domain}
                rel="noopener noreferrer"
                target="_blank"
              >
                {userOutlet.domain.length > MAX_LENGTH
                  ? `${userOutlet.domain.slice(0, MAX_LENGTH)}...`
                  : userOutlet.domain}
              </Link>
            </Typography>
          </Grid>
        </>
      );
    }

    return (
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center' }}
        className="second-step"
      >
        <Avatar
          sx={{
            width: '4rem',
            height: '4rem',
            marginRight: '1rem',
            bgcolor: 'primary.main',
          }}
        >
          <Typography variant="h2" mt="12px">
            {userOutlet.name ? userOutlet.name[0].toUpperCase() : 'C'}
          </Typography>
        </Avatar>
        <Typography variant="h4">
          Sitio web:{' '}
          <Link
            href={userOutlet.domain}
            rel="noopener noreferrer"
            target="_blank"
          >
            {userOutlet.domain}
          </Link>
        </Typography>
      </Grid>
    );
  };

  const Content = () => {
    return (
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <FirstSection />
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <Grid xs={12}>
                <Typography variant="h4">Administra su sitio web:</Typography>
              </Grid>
              <Typography
                variant="h5"
                marginTop={'1rem'}
                className="third-step"
              >
                <Link
                  href={`${process.env.REACT_APP_BACKOFFICE_URL}kaos/oficina/${userOutlet.id}/personalizar-tienda?alt=${localStorage.getItem('access')}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Personalice su tienda
                </Link>
              </Typography>
              <Typography variant="h5" className="fourth-step">
                <Link
                  href={`${process.env.REACT_APP_BACKOFFICE_URL}kaos/oficina/${userOutlet.id}/tipos_de_entrega?alt=${localStorage.getItem('access')}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  sx={{ marginTop: '1rem' }}
                >
                  Entrega y despacho
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card
            sx={{ height: outletBasicData ? '100%' : 'initial' }}
            className="fifth-step"
          >
            <CardContent>
              <Typography variant="h5">
                {!userOutlet.product_count
                  ? 'Aún no tienes productos en venta'
                  : `Productos en venta: ${userOutlet.product_count}`}
              </Typography>
              <Typography variant="h5" marginTop={'1rem'}>
                <Link
                  href={`${process.env.REACT_APP_BACKOFFICE_URL}kaos/oficina/${userOutlet.id}/catalogo_de_productos?alt=${localStorage.getItem('access')}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Administrar productos
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  return !showTour ? (
    <Content />
  ) : (
    <ReactTourProvider openSideBar={() => setOpen(true)}>
      <Content />
    </ReactTourProvider>
  );
};

export default OutletConfiguration;
