import { Chart } from 'react-google-charts';

export const data = [
  ['Producto', 'Cantidad'],
  ['A definir', 100],
];

export const options = {
  title: 'Productos Más vendidos',
};

export default function Ch() {
  return (
    <Chart chartType="PieChart" height="340px" data={data} options={options} />
  );
}
