import { useState } from 'react';
import {
  AppBar,
  // ToggleThemeButton,
  useTranslate,
  // LocalesMenuButton,
} from 'react-admin';
import { Button, IconButton, Link, Box } from '@mui/material';
import { Settings } from '@mui/icons-material';
import CompanySelector from './CompanySelector';

// import { lightTheme, darkTheme } from '../../theming/theme';

import logo from '../../assets/logo.png';
import './index.scss';

const MyAppBar = (props: any) => {
  const [selectedCompany, setSelectedCompany] = useState(0);
  const trans = useTranslate();

  return (
    <AppBar {...props} id="my-app-bar">
      <img className="logo" src={logo} alt="Famil-e logo" />

      <Box className="user-companies">
        <CompanySelector
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
        <IconButton className="company-settings">
          <Settings />
        </IconButton>
      </Box>

      <Link href="https://www.santander.cl">
        <Button className="bank-button">
          {trans('famile.my_checking_account')}
        </Button>
      </Link>
      <Link href="https://www.getnet.cl">
        <Button className="bank-button">
          {trans('famile.my_transactions')}
        </Button>
      </Link>

      {/* Temporary commented language and theme buttons */}
      {/* <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} /> */}
      {/* <LocalesMenuButton
        languages={[
          { locale: 'es', name: 'Español' },
          { locale: 'en', name: 'English' },
        ]}
      /> */}
    </AppBar>
  );
};

export default MyAppBar;
