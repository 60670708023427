import {
  Datagrid,
  List,
  NumberField,
  TextField,
  useRecordContext,
  DateField,
  SearchInput,
  SelectInput,
  DateInput,
  TextInput,
  FilterButton,
  TopToolbar,
} from 'react-admin';
import moment from 'moment';

import ActionsField from './ActionsField';

import TabsLayout from '../../components/TabsLayout';

const PackingList = () => {
  const tabs = [
    {
      index: 0,
      label: 'Todos los ingredientes',
      body: <PackingAllIngredients />,
    },
    { index: 1, label: 'Totales', body: <PackingCount /> },
  ];

  return <TabsLayout tabs={tabs} />;
};

const PackingAllIngredients = () => {
  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return record.is_packed ? (
      <span className="highlight-good">Envasado</span>
    ) : (
      <span className="highlight-warn">Pendiente</span>
    );
  };
  const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput label="Despacho desde" source="start_date" alwaysOn />,
    <DateInput label="Despacho hasta" source="end_date" alwaysOn />,
    <SelectInput
      label="Código"
      source="internal_code"
      choices={[
        { id: true, name: 'Interno' },
        { id: false, name: 'Externo' },
      ]}
      alwaysOn
    />,
    <TextInput source="hour_ready" label="Hora listo" />,
    <TextInput source="ingredient" label="Ingrediente" />,
    <TextInput source="packing_site" label="Lugar de envasado" />,
    <SelectInput
      label="Estado"
      source="is_packed"
      choices={[
        { id: true, name: 'Envasado' },
        { id: false, name: 'Pendiente' },
      ]}
    />,
  ];

  return (
    <List
      title="Packing"
      resource="packing/ingredients"
      className="ra-padding-reset"
      filters={filters}
      filterDefaultValues={{
        start_date: moment().format('yyyy-MM-DD'),
        end_date: moment().isoWeekday('Sunday').format('yyyy-MM-DD'),
        internal_code: true,
      }}
      actions={
        <TopToolbar>
          <FilterButton />
        </TopToolbar>
      }
    >
      <Datagrid bulkActionButtons={false}>
        <TextField
          label="Pedido"
          source="order.id"
          sx={{ fontWeight: 'bold' }}
        />
        <DateField label="Fecha delivery" source="order.deliver_date" />
        <TextField label="Hora listo" source="packing_ready_time" />
        <TextField label="Ingrediente" source="prepared_ingredient.name" />
        <TextField label="Lugar de envasado" source="packing_site.name" />
        <NumberField label="Cantidad" source="ordered_quantity" />
        <TextField label="Métrica" source="metric.name" />
        <TextField label="Código" source="barcode" sx={{ fontSize: 9 }} />
        <StatusField label="Estado" />
        <ActionsField />
      </Datagrid>
    </List>
  );
};

const PackingCount = () => {
  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return record.is_packed ? (
      <span className="highlight-good">Envasado</span>
    ) : (
      <span className="highlight-warn">Pendiente</span>
    );
  };
  const filters = [<SearchInput source="q" alwaysOn />];
  return (
    <List
      title="Packing"
      resource="packing/ingredients/count"
      className="ra-padding-reset"
      filters={filters}
      actions={false}
    >
      <Datagrid
        rowClick={(id, resource, record) =>
          `ingredients/count/${id}/show?ingredient_id=${record.id}&ingredient_name=${record.name}`
        }
        bulkActionButtons={false}
      >
        <TextField label="Nombre" source="name" />
        <NumberField label="Cantidad" source="count" />
        <StatusField label="Estado" />
      </Datagrid>
    </List>
  );
};

export default PackingList;
