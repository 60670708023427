import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  SimpleForm,
  TextInput,
  SelectInput,
  DateInput,
  useGetList,
  Toolbar,
  SaveButton,
  Edit,
  useUpdate,
  BooleanInput,
} from 'react-admin';
import { useNotify } from 'ra-core';
import { Typography, Grid, Divider, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import MoneyInput from '../../components/MoneyInput';
import SelectInputPaginated from '../../components/SelectInputPaginated';

const CashFlowEdit = () => {
  const navigate = useNavigate();
  const [update] = useUpdate();
  const notify = useNotify();

  const [businessNamesOptions, setBusinessNamesOptions] = useState<any>([]);

  const { data: businessNames } = useGetList('business_names');
  useEffect(() => {
    setBusinessNamesOptions(
      businessNames
        ? businessNames.map((businessName) => ({
            id: businessName.id,
            name: businessName.name,
          }))
        : []
    );
  }, [businessNames]);

  const postSave = (data: any) => {
    update(
      'cash_flows',
      {
        id: data.id,
        data: {
          ...data,
          company: data.company.id,
          provider: data.provider.id || data.provider,
          flow_type: data.flow_type.id,
        },
      },
      {
        onSuccess: () => {
          notify('Movimiento de caja actualizado con éxito', {
            type: 'success',
          });
        },
        onError: () => {
          notify('No se pudo actualizar el movimiento de caja', {
            type: 'warning',
            messageArgs: 'Error',
          });
        },
      }
    );
  };

  return (
    <Edit resource="cash_flows" className="ra-margin-top">
      <Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
        Volver
      </Button>
      <SimpleForm
        id="cash-flows-edit"
        width={'100%'}
        toolbar={
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <SaveButton />
          </Toolbar>
        }
        onSubmit={postSave}
      >
        <Typography variant="h4" mb={3}>
          Editar movimiento de caja manual
        </Typography>

        <Divider />
        <Typography variant="h5" className="subtitle" mb={2}>
          Despacho
        </Typography>
        <Grid container spacing={{ md: 3, xs: 0 }}>
          <Grid item xs={12} md={3}>
            <TextInput
              label="Sociedad"
              source="company.name"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <SelectInput
              required
              label="¿Ingreso o gasto?"
              source="flow_type.id"
              choices={[
                { id: 0, name: 'Egreso' },
                { id: 1, name: 'Ingreso' },
              ]}
              fullWidth
              isRequired
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <SelectInputPaginated
              label="Contraparte"
              source="providers"
              defaultValueKey="provider"
              sourceKey="provider"
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 3, xs: 0 }}>
          <Grid item xs={12} md={3}>
            <TextInput
              label="Nº documento"
              source="doc_number"
              fullWidth
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <DateInput label="Fecha" source="date" fullWidth required />
          </Grid>
          <Grid item md={3} xs={12}>
            <DateInput
              label="Fecha de vencimiento"
              source="due_date"
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 3, xs: 0 }}>
          <Grid item xs={12} md={3}>
            <MoneyInput
              label="Monto"
              source="amount"
              sx={{ width: { md: '97%', xs: '96%' } }}
              required
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <SelectInput
              label={'Razón social'}
              source="business_name"
              choices={businessNamesOptions}
              fullWidth
              required
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <BooleanInput label="¿Cancelado?" source="is_canceled" />
          </Grid>
        </Grid>

        <Grid container spacing={{ md: 3, xs: 0 }}>
          <Grid item xs={12} md={9}>
            <TextInput label="Comentarios" source="comments" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default CashFlowEdit;
