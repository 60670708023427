import { Card, CardContent, Grid } from '@mui/material';

import BarChart from './BarChart';
import PieChart from './PieChart';
import SteppedAreaChart from './SteppedAreaChart';

const Charts = () => {
  return (
    <>
      <Grid item md={8} xs={12}>
        <Card>
          <CardContent>
            <BarChart />
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4} xs={12}>
        <Card>
          <PieChart />
        </Card>
      </Grid>
      <Grid item md={12} xs={12}>
        <Card>
          <SteppedAreaChart />
        </Card>
      </Grid>
    </>
  );
};

export default Charts;
