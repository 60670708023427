import { useState } from 'react';
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  useRecordContext,
  RaRecord,
  useDataProvider,
  EditButton,
} from 'react-admin';
import { Tooltip, IconButton, Typography, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { useMutation } from 'react-query';

import BaseModal from '../../components/BaseModal';

const Deliveries = () => {
  const [openDispatchModal, setOpenDispatchModal] = useState(false);
  const [dispatchModalBody, setDispatchModalBody] = useState(<></>);

  const RangeField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <span>{`Desde ${record.min_deliver_time} hasta ${record.max_deliver_time}`}</span>
    );
  };

  const DispatchField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <EditButton
        label="Editar grupo"
        color="secondary"
        variant="outlined"
        onClick={(e) => {
          // This is for the row not receiving the click event and running the rowClick action when we click on the button
          e.stopPropagation();
          e.preventDefault();
          setOpenDispatchModal(true);
          setDispatchModalBody(<ModalBody order={record} />);
        }}
      />
    );
  };

  const AddressField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <span>
        <b>{`${record.address.street} ${record.address.number}. `}</b>
        {record.address.apartment
          ? `Apartamento: ${record.address.apartment}`
          : ''}
      </span>
    );
  };

  const ModalBody = ({ order }: { order: RaRecord }) => {
    const OtherOrdersField = ({ label }: { label: string }) => {
      const record = useRecordContext();
      if (record.deliveries.other_orders.length) {
        return (
          <ul>
            {record.deliveries.other_orders.map((delivery: any) => (
              <li>{`${delivery.commune}, ${delivery.hour}`}</li>
            ))}
          </ul>
        );
      } else {
        return <span>No hay</span>;
      }
    };

    const GroupField = ({ label }: { label: string }) => {
      const record = useRecordContext();
      const dataProvider = useDataProvider();
      const { mutate, isLoading } = useMutation(
        [
          `group_order_${order.id}`,
          'update',
          { id: order.id, data: { delivery_profile: record.delivery_guy.id } },
        ],
        () =>
          dataProvider.update(`deliveries`, {
            id: order.id,
            data: { delivery_profile: record.delivery_guy.id },
            previousData: undefined,
          })
      );

      return (
        <>
          <Button
            variant="outlined"
            className="datagrid-button"
            onClick={() => mutate()}
          >
            {record.deliveries.belong_to_this_order
              ? 'Quitar de grupo'
              : 'Agregar al grupo'}
          </Button>
        </>
      );
    };

    return (
      <div style={{ width: '50vw' }}>
        <Typography variant="h3">{`Pedido ${order.id}`}</Typography>
        <Typography>{`${order.deliver_date}, ${order.deliver_time}`}</Typography>
        <Typography>
          {`${order.address.street} ${order.address.number}, ${order.address.commune.name}`}
        </Typography>

        <List resource={`deliveries/${order.id}/delivery_groups`}>
          <Datagrid bulkActionButtons={false}>
            <TextField label="Despachante" source="delivery_guy.username" />
            <OtherOrdersField label="Otros pedidos" />
            <GroupField label="Grupo" />
          </Datagrid>
        </List>

        {/* <div>
          <Typography variant="h6">Nuevo despachante</Typography>
        </div> */}
      </div>
    );
  };

  return (
    <>
      <BaseModal
        open={openDispatchModal}
        setOpen={setOpenDispatchModal}
        body={dispatchModalBody}
      />
      <List
        resource="deliveries"
        className="ra-padding-reset ra-margin-top"
        actions={false}
      >
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField sx={{ fontWeight: 'bold' }} label="Pedido" source="id" />
          <TextField label="Fecha" source="deliver_date" />
          <NumberField label="Hora" source="deliver_time" />
          <RangeField label="Rango" />
          <NumberField label="Comuna" source="address.commune.name" />
          <AddressField label="Dirección" />
          <TextField
            label="Estado"
            source="status"
            className="highlight-good"
          />
          <DispatchField label="Despacho" />
        </Datagrid>
      </List>
    </>
  );
};

export default Deliveries;
