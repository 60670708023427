import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
import MySidebar from './MySidebar';
import MyMenu from './MyMenu';

const MyLayout = (props: any) => (
  <Layout
    {...props}
    id="my-layout"
    appBar={MyAppBar}
    sidebar={MySidebar}
    menu={MyMenu}
  />
);

export default MyLayout;
