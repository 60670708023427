import { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  useDelete,
  DateInput,
  SearchInput,
  useGetList,
  SelectInput,
} from 'react-admin';
import { IconButton, CircularProgress } from '@mui/material';
import {
  Delete as DeleteIcon,
  Done as DoneIcon,
  Close as CloseIcon,
  HorizontalRule,
} from '@mui/icons-material';

import LongField from '../../components/LongField';
import MoneyField from '../../components/MoneyField';
import CustomPagination from '../../components/CustomPagination';

const Paysheets = () => {
  const [businessNamesOptions, setBusinessNamesOptions] = useState<any>([]);

  const { data: businessNames } = useGetList('business_names');

  useEffect(() => {
    setBusinessNamesOptions(
      businessNames
        ? businessNames.map((businessName) => ({
            id: businessName.id,
            name: businessName.name,
          }))
        : []
    );
  }, [businessNames]);

  const DeleteButton = ({ label }: { label: string }) => {
    const record = useRecordContext();

    const [deleteOne, { isLoading }] = useDelete(`paysheets`, {
      id: record.id,
    });

    return record.paid ? (
      <IconButton color="error" component="label" onClick={() => deleteOne()}>
        {isLoading ? (
          <CircularProgress size={20} color="error" />
        ) : (
          <DeleteIcon />
        )}
      </IconButton>
    ) : (
      <HorizontalRule color="inherit" />
    );
  };

  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return record.paid ? (
      <DoneIcon color="success" />
    ) : (
      <CloseIcon color="error" />
    );
  };

  const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput label="Nómina desde" source="start_date" alwaysOn />,
    <DateInput label="Nómina hasta" source="end_date" alwaysOn />,
    <SelectInput
      label="Razón Social"
      source="business_name"
      choices={businessNamesOptions}
      alwaysOn
    />,
  ];

  return (
    <>
      <List
        resource="paysheets"
        hasCreate
        exporter={false}
        filters={filters}
        className="ra-margin-top"
        pagination={<CustomPagination />}
      >
        <Datagrid bulkActionButtons={false}>
          <DateField label="Fecha" source="date" />
          <TextField label="Contraparte" source="provider.name" />
          <LongField number label="Documento" source="doc_number" />
          <MoneyField label="Monto" source="amount" />
          <StatusField label="Estado" />
          <DeleteButton label="Acciones" />
        </Datagrid>
      </List>
    </>
  );
};

export default Paysheets;
