import { useState } from 'react';
import {
  List,
  Datagrid,
  NumberField,
  TextField,
  useRecordContext,
  RaRecord,
} from 'react-admin';
import { Typography, Button, Divider } from '@mui/material';
import { LocalShipping, Error, Verified } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { generateColorFromString } from '../../utils';

const DeliveriesToday = () => {
  const navigate = useNavigate();

  const RangeField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <span>{`Desde ${record.min_deliver_time} hasta ${record.max_deliver_time}`}</span>
    );
  };

  const AddressField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <span>
        <b>{`${record.address.street} ${record.address.number}. `}</b>
        {record.address.apartment
          ? `Apartamento: ${record.address.apartment}`
          : ''}
      </span>
    );
  };

  const GroupField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <div>
        <Typography color="secondary">
          {record.delivery.delivery_group
            ? record.delivery.delivery_group.delivery_guy.username
            : '---'}
        </Typography>
        <Divider sx={{ margin: 1 }} />
        {record.delivery.delivery_group ? (
          record.delivery.delivery_group.truck_is_loaded ? (
            <>
              <Verified color="primary" /> Listo para despachar
            </>
          ) : (
            <>
              <LocalShipping /> Falta cargar en camión
            </>
          )
        ) : (
          <>
            <Error /> No hay grupo asignado
          </>
        )}
      </div>
    );
  };

  const rowStyle = (record: RaRecord) => ({
    backgroundColor: generateColorFromString(
      record.delivery.delivery_group.delivery_guy.username
    ),
  });

  const ActionButton = () => {
    const record = useRecordContext();
    let onClick: Function = () => {
      console.log('asociar grupo');
    };
    let label: string = 'Asociar grupo';
    if (record.delivery.delivery_group) {
      if (record.delivery.delivery_group.truck_is_loaded) {
        label = 'Despachar';
        onClick = () => {
          console.log('ejhe');
        };
      } else {
        label = 'Cargar';
        onClick = () => {
          // Go to delivery page for load it in truck
          navigate(`${record.id}/load_truck`);
        };
      }
    }

    return (
      <Button
        className="datagrid-button"
        variant="outlined"
        onClick={() => onClick()}
      >
        {label}
      </Button>
    );
  };

  return (
    <>
      <List
        resource="deliveries"
        className="ra-padding-reset"
        filter={{ deliver_date: '2020-12-22' }}
        actions={false}
      >
        <Datagrid rowStyle={rowStyle} bulkActionButtons={false}>
          <TextField sx={{ fontWeight: 'bold' }} label="Pedido" source="id" />
          <TextField label="Fecha" source="deliver_date" />
          <NumberField label="Hora" source="deliver_time" />
          <NumberField label="Comuna" source="address.commune.name" />
          <AddressField label="Dirección" />
          <ActionButton />
          <GroupField label="Grupo" />
        </Datagrid>
      </List>
    </>
  );
};

export default DeliveriesToday;
