import TabsLayout from '../../components/TabsLayout';
import Pickups from './Pickups';
import PickupsToday from './PickupsToday';

const Pickup = () => {
  const tabs = [
    { index: 0, label: 'Retiros en local', body: <Pickups /> },
    { index: 1, label: 'Retiros pendientes hoy', body: <PickupsToday /> },
  ];

  return <TabsLayout tabs={tabs} />;
};

export default Pickup;
