import { Chart } from 'react-google-charts';

export const data = [
  ['Período', 'Clientes recurrentes', 'Clientes nuevos'],
  ['2021', 0, 0],
  ['2022', 0, 0],
  ['2023', 0, 0],
];

export const options = {
  title: 'Clientes',
};

export default function Ch() {
  return (
    <Chart
      chartType="SteppedAreaChart"
      height="300px"
      data={data}
      options={options}
    />
  );
}
