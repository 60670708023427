import { useListContext } from 'react-admin';
import TablePagination from '@mui/material/TablePagination';

const CustomPagination = () => {
  const { page, perPage, total, setPage, setPerPage, isLoading } =
    useListContext();

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  if (isLoading) {
    return null;
  }

  const labelDisplayedRows = ({ from, to, count }: any) => {
    const totalPages = Math.ceil(count / perPage);
    return `${from} - ${to} de ${totalPages}`;
  };

  return (
    <TablePagination
      component="div"
      count={total}
      rowsPerPage={perPage}
      page={page - 1}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      sx={{ visibility: 'visible' }}
      labelRowsPerPage={'Nro. Filas'}
      labelDisplayedRows={labelDisplayedRows}
      showFirstButton={false}
    />
  );
};

export default CustomPagination;
