import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography, Grid, Button, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useNotify } from 'ra-core';
import BaseModal from '../../components/BaseModal';
import { simpleFetch } from '../../utils/fetch';

const styles = {
  container: {
    minHeight: '100vh',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const MyCreateCompany = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [openInvalidState, setOpenInvalidState] = useState(false);
  const [invalidState, setInvalidState] = useState(false);
  const [name, setName] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const access = searchParams.get('code');
    if (access || localStorage.getItem('access')) {
      setOpen(true);
    } else if (!access && !localStorage.getItem('access')) {
      setOpenInvalidState(true);
      setInvalidState(true);
    }

    if (access) {
      localStorage.setItem('access', `${access}`);
      // delete query param:
      searchParams.delete('code');

      //update state after:
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  const createCompany = async () => {
    setLoading(true);
    // Set token at the begining to use it on the requet. Then delete it
    const access = localStorage.getItem('access');

    const resp = await simpleFetch({
      url: `user_company`,
      method: 'POST',
      auth: true,
      body: {
        outlet_company_name: name,
      },
    });
    if (resp.ok) {
      navigate({
        pathname: '/login',
        search: `?access=${access}`,
      });
    } else {
      notify('Error en la creación de la compania', {
        type: 'warning',
        messageArgs: 'Error',
      });
    }
    localStorage.removeItem('access');
    setLoading(false);
  };

  return (
    <Grid
      container
      sx={{
        ...styles.container,
      }}
    >
      <BaseModal
        open={open}
        setOpen={setOpen}
        body={
          <Grid
            style={{
              padding: '1rem',
              backgroundColor: 'white',
            }}
          >
            <Typography variant="h4">¡Validación exitosa!</Typography>
          </Grid>
        }
      />
      <BaseModal
        open={openInvalidState}
        setOpen={setOpenInvalidState}
        body={
          <Grid
            style={{
              padding: '1rem',
              backgroundColor: 'white',
            }}
          >
            <Typography variant="h4">
              Datos incorrectos, no será posible crear su empresa
            </Typography>
          </Grid>
        }
      />
      <Grid item xs={12} md={5} mt={4} sx={{ color: 'white' }}>
        <Typography variant="h3" className="subtitle">
          ¡Bienvenido a Famil-e!
        </Typography>
        <Typography variant="h5" className="subtitle">
          {`Estás a pocos pasos de digitalizar y hacer crecer tu negocio. \n
        Para empezar a funcionar necesitamos información básica sobre tu empresa.`}
        </Typography>
      </Grid>

      <Grid item xs={12} md={5} mt={4}>
        <TextField
          label={'Nombre'}
          sx={{
            width: { xs: '80vw', md: '30rem' },
            [`& .MuiInputLabel-root`]: { color: 'white' },
            [`& .MuiOutlinedInput-root`]: {
              color: 'white',
            },
            [`& .MuiOutlinedInput-notchedOutline`]: {
              borderColor: 'white',
            },
          }}
          onChange={(e) => {
            setName(e.target.value);
          }}
          error={name === ''}
        />
      </Grid>

      <Grid item xs={12} md={5} mt={4}>
        <Button
          variant="contained"
          color="primary"
          disabled={!name || invalidState}
          startIcon={<CheckIcon />}
          onClick={() => {
            if (!loading) {
              createCompany();
            }
          }}
        >
          ¡Crear empresa!
        </Button>
      </Grid>
    </Grid>
  );
};

export default MyCreateCompany;
