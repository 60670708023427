import { useState } from 'react';
import { Menu, useTranslate, useSidebarState } from 'react-admin';
import {
  Dvr,
  LocalShippingOutlined,
  InventoryOutlined,
  QueryStatsOutlined,
  CurrencyExchange,
  FormatListNumbered,
  ReceiptLong,
  ReceiptOutlined,
  RequestQuoteOutlined,
  Dashboard,
  Storefront,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import SubMenu from './SubMenu';
import CompanySelector from './CompanySelector';

const MyMenu = () => {
  const trans = useTranslate();
  const [open] = useSidebarState();
  const [selectedCompany, setSelectedCompany] = useState(0);

  const headerMenuItemStyle = {
    '&.RaMenuItemLink-active': {
      borderLeft: `none`,
    },
    display: { md: 'none', xs: open ? 'block' : 'none' },
  };

  return (
    <Menu>
      <Menu.Item
        to="/dashboard"
        primaryText="Dashboard"
        leftIcon={<Dashboard />}
      />
      <Menu.Item
        to="/ecommerce"
        primaryText="Ecommerce"
        leftIcon={<Storefront />}
      />
      {/* The code below is for displaying a Menu item per resource. Renders all items for resources declared in the Admin component, in App.tsx.
      But as we have no pure RA resources in App.tsx, we don't use this code for now and instead show our custom SubMenu components.
        Object.keys(resources).map((name) => (
          <Menu.Item
            key={name}
            to={`/${name}`}
            primaryText={
              (resources[name].options && resources[name].options.label) || name
            }
            leftIcon={
              resources[name].icon ? (
                createElement(resources[name].icon)
              ) : (
                <LabelImportantIcon />
              )
            }
          />
        ))} */}

      {/* Custom menu items */}
      {/* Orders management */}
      <SubMenu primaryText="menu.orders_management.a" leftIcon={<Dvr />}>
        {/* Note: the routes for this are declared in the Admin component. (App.tsx) */}
        <Menu.Item
          to="/orders"
          primaryText="menu.orders_management.list"
          leftIcon={<FormatListNumbered />}
        />
        <Menu.Item
          to="/packing"
          primaryText="menu.packing.list"
          leftIcon={<ReceiptLong />}
        />

        <Menu.Item
          to="/boxes"
          primaryText="menu.orders_management.box_assembling"
          leftIcon={<InventoryOutlined />}
        />
        <Menu.Item
          to="/deliveries"
          primaryText="menu.orders_management.deliveries"
          leftIcon={<LocalShippingOutlined />}
        />
        {/* TODO */}
        {/* <Menu.Item
          to="/pickup"
          primaryText="menu.orders_management.pickup"
          leftIcon={<LocalMallOutlined />}
        /> */}

        {/* Note: this submenu will be commented until we return to the packing administration */}
        {/* <SubMenu primaryText="menu.packing.a" leftIcon={<ReceiptLong />}> */}
        {/* Note: the routes for this are declared in the Admin component. (App.tsx) */}
        {/* <Menu.Item
            to="/packing"
            primaryText="menu.packing.list"
            leftIcon={<FormatListNumbered />}
          />
          <Menu.Item
            to="/packing/management"
            primaryText="menu.packing.management"
            leftIcon={<InventoryOutlined />}
          /> */}
        {/* TODO */}
        {/* <Menu.Item
          to="/packing/transfer"
          primaryText="menu.packing.transfers"
          leftIcon={<LocalShippingOutlined />}
        /> */}
        {/* </SubMenu> */}
      </SubMenu>

      <Menu.Item
        to="/providers_purchases"
        primaryText="menu.providers_purchases"
        leftIcon={<ReceiptOutlined />}
      />

      {/* Packing */}

      {/* Management */}
      <SubMenu
        primaryText="menu.management.a"
        leftIcon={<QueryStatsOutlined />}
      >
        {/* Note: the routes for this are declared in the Admin component. (App.tsx) */}
        <Menu.Item
          to="/finances/dashboard"
          primaryText="Dashboard"
          leftIcon={<Dashboard />}
        />
        <Menu.Item
          to="/cash_flows"
          primaryText="menu.management.cash_flows"
          leftIcon={<CurrencyExchange />}
        />
        <Menu.Item
          to="/paysheets"
          primaryText="menu.management.paysheets"
          leftIcon={<RequestQuoteOutlined />}
        />
      </SubMenu>

      {/* Header menu */}
      <Menu.Item
        to={''}
        primaryText={
          <CompanySelector
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
        }
        sx={headerMenuItemStyle}
      />

      <Menu.Item
        to={''}
        primaryText={
          <Button
            className="bank-button"
            onClick={() => window.open(`https://www.santander.cl`, '_blank')}
          >
            {trans('famile.my_checking_account')}
          </Button>
        }
        sx={headerMenuItemStyle}
      />
      <Menu.Item
        to={''}
        primaryText={
          <Button
            className="bank-button"
            onClick={() => window.open(`https://www.getnet.cl`, '_blank')}
          >
            {trans('famile.my_transactions')}
          </Button>
        }
        sx={headerMenuItemStyle}
      />
    </Menu>
  );
};

export default MyMenu;
