import { Edit } from 'react-admin';

import TabsLayout from '../../../components/TabsLayout';
import EditForm from './EditForm';
import Ingredients from './Ingredients';

const OrderEdit = () => {
  const tabs = [
    { index: 0, label: 'Orden', body: <EditForm /> },
    { index: 1, label: 'Ingredientes', body: <Ingredients /> },
  ];

  // We need to wrap the TabsLayout in an Edit component to get the record in the editContext of each tab component
  return (
    <Edit resource="orders" className="ra-margin-top">
      <TabsLayout tabs={tabs} />
    </Edit>
  );
};

export default OrderEdit;
