import { useEffect, useState } from 'react';
import { useInfiniteGetList, useInput, useRecordContext } from 'react-admin';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// TODO: We should add a loading state to this component for when the data is being fetched from the backend.
// And a search bar to filter the options would be nice too, cause there could be a lot of options.

type SelectInputPaginatedProps = {
  source: string; // The name of the source that's gonna be requested to backend and get all options to display.
  sourceKey: string; // The property of the record that's gonna be saved.
  label: string;
  defaultValueKey?: string; // If the record already exists and has a value for this property, it will be displayed as the default value.
  // So here goes the name of the property that has the value to display at first, as default.
  disabled?: boolean;
  required?: boolean;
};
/* 
Note: Remember "defaultValueKey" will be only used when this component is inside an Edit component. 
For Create components, the defaultValueKey prop is not needed. Cause the record won't exist yet, so neither that property.
*/

const SelectInputPaginated = ({
  source,
  sourceKey,
  label,
  disabled,
  defaultValueKey,
  required,
}: SelectInputPaginatedProps) => {
  const { field } = useInput({ source: sourceKey });
  const record = useRecordContext() || null;
  const [options, setOptions] = useState<any>([]);
  const [value, setValue] = useState<string>(
    defaultValueKey ? record[defaultValueKey].id : null
  );
  const { data, fetchNextPage, hasNextPage } = useInfiniteGetList(source, {
    pagination: { page: 1, perPage: 10 },
  });

  const defaultValueObj = defaultValueKey ? record[defaultValueKey] : null;
  useEffect(() => {
    let optionsArray: any[] = defaultValueObj ? [defaultValueObj] : [];
    if (data) {
      for (const page of data.pages) {
        for (const obj of page.data) {
          if (!optionsArray[0] || obj.id !== optionsArray[0].id) {
            optionsArray.push(obj);
          }
        }
      }
    }

    setOptions(optionsArray);
  }, [data, defaultValueObj]);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="infinite-scroll-select" variant="outlined">
        {label}
      </InputLabel>
      <Select
        variant="outlined"
        id={`infinite-scroll-select-${label}`}
        value={value}
        label={label}
        disabled={disabled}
        required={required}
        onChange={(event: SelectChangeEvent) => {
          setValue(event.target.value);
          field.onChange(event);
        }}
        MenuProps={{
          PaperProps: {
            onScroll: (event: any) => {
              if (
                event.target.scrollHeight ===
                  event.target.scrollTop + event.target.clientHeight &&
                hasNextPage
              ) {
                fetchNextPage();
              }
            },
          },
          sx: { maxHeight: 300 },
        }}
        sx={{ minWidth: '200px' }}
      >
        {options?.map((option: any) => {
          return <MenuItem value={option.id}>{option.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
export default SelectInputPaginated;
