import { useGetList } from 'react-admin';
import { Select, MenuItem } from '@mui/material';
import { simpleFetch } from '../../utils/fetch';
import './index.scss';

interface CompanySelectorProps {
  selectedCompany: number;
  setSelectedCompany: (company: number) => any;
}

const CompanySelector = ({
  selectedCompany,
  setSelectedCompany,
}: CompanySelectorProps) => {
  const handleCompanyChange = (event: any) => {
    simpleFetch({ url: `companies/${event.target.value}`, method: 'PUT' });
    setSelectedCompany(event.target.value);
  };

  // Get user's companies when component mounts
  const { data: companies } = useGetList('companies');

  return (
    <Select
      value={selectedCompany}
      className="select"
      onChange={handleCompanyChange}
      sx={{ height: '30px' }}
    >
      <MenuItem value={0}>Todas mis compañías</MenuItem>
      {companies &&
        companies.map((company: { id: number; name: string }) => (
          <MenuItem value={company.id} key={company.id}>
            {company.name}
          </MenuItem>
        ))}
    </Select>
  );
};

export default CompanySelector;
