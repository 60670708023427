import TabsLayout from '../../components/TabsLayout';
import Deliveries from './Deliveries';
import DeliveriesToday from './DeliveriesToday';

const Delivery = () => {
  const tabs = [
    { index: 0, label: 'Administrar despachos', body: <Deliveries /> },
    { index: 1, label: 'Despachos pendientes hoy', body: <DeliveriesToday /> },
  ];

  return <TabsLayout tabs={tabs} />;
};

export default Delivery;
