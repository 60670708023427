import { useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  NumberInput,
  useGetList,
} from 'react-admin';
import { Typography, Grid, Divider, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

import SelectInputPaginated from '../../components/SelectInputPaginated';
import MoneyInput from '../../components/MoneyInput';

const FlowCreate = () => {
  const [businessNamesOptions, setBusinessNamesOptions] = useState<any>([]);
  const [companiesOptions, setCompaniesOptions] = useState<any>([]);
  const [searchParams] = useSearchParams();
  const presetFlowType = searchParams.get('flow_type') || 0; // 0 = Egreso, 1 = Ingreso;
  const { data: providers } = useGetList('providers');
  const { data: businessNames } = useGetList('business_names');
  const { data: companies } = useGetList('companies');

  // Once the data provider retrieved the data, we can use it to populate the options
  useEffect(() => {
    setBusinessNamesOptions(
      businessNames
        ? businessNames.map((businessName) => ({
            id: businessName.id,
            name: businessName.name,
          }))
        : []
    );
    setCompaniesOptions(
      companies
        ? companies.map((company) => ({
            id: company.id,
            name: company.name,
          }))
        : []
    );
  }, [companies, businessNames, providers]);

  const validateFlowCreation = (values: any) => {
    type errorsType = { confirmed_amount: string };
    let errors: errorsType = {
      confirmed_amount: '',
    };
    if (values.amount !== values.confirmed_amount) {
      errors.confirmed_amount = 'Los montos no coinciden';
    }

    // TODO make this translatable with the following format
    // errors.age = {
    //   message: 'ra.validation.minValue',
    //   args: { min: 18 },
    // };

    // Iterate in erros and if there is any error, return the errors object. In otherwise return {} to indicate no errors to SimpleForm
    for (let error of Object.values(errors)) {
      if (error) {
        return errors;
      }
    }
    return {};
  };

  return (
    <Box className="ra-margin-top">
      <Typography variant="h4">Nuevo flujo de caja</Typography>
      <Create resource="cash_flows">
        <SimpleForm validate={validateFlowCreation}>
          <Divider />
          <Typography variant="h5" className="subtitle" mb={2}>
            Despacho
          </Typography>
          <Grid container spacing={{ md: 3, xs: 0 }}>
            <Grid item xs={12} md={3}>
              <SelectInput
                fullWidth
                label="Razón Social"
                source="business_name"
                choices={businessNamesOptions}
                required
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectInput
                fullWidth
                required
                label="Movimiento"
                source="flow_type"
                choices={[
                  { id: 0, name: 'Egreso' },
                  { id: 1, name: 'Ingreso' },
                ]}
                defaultValue={presetFlowType}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectInput
                fullWidth
                label="Compañía"
                source="company"
                choices={companiesOptions}
                required
              />
            </Grid>
          </Grid>

          <Grid container spacing={{ md: 3, xs: 0 }}>
            <Grid item md={4} xs={12}>
              <SelectInputPaginated
                label="Contraparte"
                source="providers"
                sourceKey="provider"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <DateInput source="date" label="Fecha" required fullWidth />
                </Grid>
                <Grid item md={6} xs={12}>
                  <DateInput
                    source="due_date"
                    label="Vencimiento"
                    required
                    defaultValue={moment().format('YYYY-MM-DD')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={{ md: 3, xs: 0 }}>
            <Grid item xs={12} md={3}>
              <MoneyInput
                label="Monto"
                source="amount"
                sx={{ width: { md: '97%', xs: '96%' } }}
                required
              />
              <MoneyInput
                label="Confirmación de monto"
                source="confirmed_amount"
                confirmation
                sx={{ width: { md: '97%', xs: '96%' } }}
                required
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <NumberInput source="doc_number" label="Nº documento" required />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={{ md: 3, xs: 0 }}
            marginBottom={{ md: 0, xs: '2rem' }}
          >
            <Grid item xs={12} md={9}>
              <TextInput
                label="Comentarios"
                source="comments"
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    </Box>
  );
};

export default FlowCreate;
