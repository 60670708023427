import raLanguageSpanish from '@blackbox-vision/ra-language-spanish';

// IMPORTANT **********
// Keep the translation keys albaphetically ordered motherfucker!

// Define custom messages we need for the app, in base of the ra-language-spanish package so we have all spanish translations too
const es = {
  ...raLanguageSpanish,
  famile: {
    my_checking_account: 'Cuenta corriente',
    my_transactions: 'Mis transacciones',
  },

  menu: {
    management: {
      a: 'Finanzas',
      cash_flows: 'Flujos de caja',
      paysheets: 'Nóminas',
    },
    orders: 'Pedidos',
    orders_management: {
      a: 'Gestión de pedidos',
      list: 'Pedidos',
      box_assembling: 'Armado de paquetes',
      deliveries: 'Despachos',
      pickup: 'Retiros en local',
    },
    packing: {
      a: 'Envasado',
      list: 'Envasado',
      management: 'Administración',
      transfers: 'Traslados',
    },
    providers_purchases: 'Compras',
  },
  signup: {
    already_signup: '¿Ya tienes una cuenta? Inicia sesión',
    create_user: 'Crear usuario',
    confirm_email: 'Confirme su correo electrónico',
    confirm_password: 'Confirmar contraseña',
    email: 'Correo electrónico',
    first_name: 'Nombre',
    last_name: 'Apellido',
    not_registered: '¿No tienes cuenta? Click aquí para crear una',
  },
};

export default es;
