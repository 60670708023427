import { useRecordContext } from 'react-admin';

import { moneyFormat } from '../utils';

type MoneyFieldProps = {
  source: string;
  label?: string;
  sx?: any;
};

const MoneyField = (props: MoneyFieldProps) => {
  const record = useRecordContext();

  return (
    <>
      <span style={{ float: 'right' }}>
        {moneyFormat(record[props.source])}
      </span>
    </>
  );
};

export default MoneyField;
