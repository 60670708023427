import { useState } from 'react';
import moment from 'moment';
import {
  useGetOne,
  List,
  Datagrid,
  TextField as TextFieldRA,
  useRecordContext,
} from 'react-admin';
import {
  Paper,
  Grid,
  Button,
  Card,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  LinearProgress,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Inventory, MoveToInbox } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import { moneyFormat } from '../../utils';

const LoadTruck = () => {
  const { id } = useParams();
  console.log({ id });
  const { data: record } = useGetOne('deliveries', { id });

  console.log({ record });

  return (
    <>
      <Grid container spacing={2} className="ra-margin-top">
        {/* Order info */}
        <Grid item xs={6}>
          <Card>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <h2>Info. del pedido</h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Cliente</strong>
                    </TableCell>
                    <TableCell>Mañungo</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Cajas</strong>
                    </TableCell>
                    <TableCell>fff</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Fecha</strong>
                    </TableCell>
                    <TableCell>ffff</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hora pedido</strong>
                    </TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hora salida</strong>
                    </TableCell>
                    <TableCell>-----</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Teléfono cliente</strong>
                    </TableCell>
                    <TableCell>-----</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Productos</strong>
                    </TableCell>
                    <TableCell>-----</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <h2>Info. del pedido</h2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Cliente</strong>
                    </TableCell>
                    <TableCell>Mañungo</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Cajas</strong>
                    </TableCell>
                    <TableCell>fff</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Fecha</strong>
                    </TableCell>
                    <TableCell>ffff</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hora pedido</strong>
                    </TableCell>
                    <TableCell>0</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hora salida</strong>
                    </TableCell>
                    <TableCell>-----</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Teléfono cliente</strong>
                    </TableCell>
                    <TableCell>-----</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Productos</strong>
                    </TableCell>
                    <TableCell>-----</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default LoadTruck;
