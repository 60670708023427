import { useState } from 'react';
import moment from 'moment';
import {
  useEditController,
  List,
  Datagrid,
  TextField as TextFieldRA,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {
  Paper,
  Grid,
  Button,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  LinearProgress,
  TextField,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Inventory, MoveToInbox, RestoreFromTrash } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import { moneyFormat } from '../../utils';
import { simpleFetch } from '../../utils/fetch';

const LoadBox = () => {
  const [barcode, setBarcode] = useState('');

  const { id } = useParams();
  const { record } = useEditController({
    resource: 'boxes',
    id,
  });

  const handleBarcodeChange = (code: string) => {
    if (!isNaN(Number(code))) {
      setBarcode(code);
    }
  };

  return (
    <>
      <Grid container spacing={2} className="ra-margin-top">
        {/* Order info */}
        <Grid item xs={4}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h1>Pedido {record.id}</h1>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Comercio</strong>
                  </TableCell>
                  <TableCell>Mañungo</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Estado de caja</strong>
                  </TableCell>
                  <TableCell>
                    {record.progress.currently_completed ===
                    record.progress.total
                      ? 'Completada'
                      : 'Sin completar'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Fecha</strong>
                  </TableCell>
                  <TableCell>
                    {moment(record.deliver_date, 'yyyy-MM-DD').format(
                      'D [de] MMMM [de] yy'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Número de cajas</strong>
                  </TableCell>
                  <TableCell>{record.boxes.length}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Comentarios de oficina</strong>
                  </TableCell>
                  <TableCell>
                    {record.comments_for_box_assembly || '------'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h2>Llenado de caja</h2>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="right">
                    <span>
                      {record.progress.currently_completed}/
                      {record.progress.total}
                    </span>
                    <LinearProgress
                      variant="determinate"
                      sx={{ height: '8px' }}
                      value={
                        (record.progress.total /
                          record.progress.currently_completed) *
                        100
                      }
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="right">
                    <TextField
                      value={barcode}
                      helperText="Utilice el lector o su teclado"
                      label="Código de barras"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => {
                        handleBarcodeChange(e.target.value);
                      }}
                    />
                    <Button
                      variant="contained"
                      startIcon={<Inventory />}
                      onClick={() =>
                        simpleFetch({
                          url: `boxes/${id}/`,
                          method: 'PATCH',
                          body: {
                            close_box: true,
                          },
                        })
                      }
                    >
                      Cerrar caja
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <h3 style={{ marginBottom: 0 }}>
                      Precio total del pedido: {moneyFormat(record.total_price)}
                    </h3>
                    <small>(Incluyendo descuentos)</small>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={8}>
          <IngredientsList boxId={record.id} />
        </Grid>
      </Grid>
    </>
  );
};

const IngredientsList = ({ boxId }: { boxId: Number }) => {
  const refresh = useRefresh();

  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    if (record.is_packed) {
      return <span className="highlight-good">Empacado</span>;
    } else {
      return <span className="highlight-warn">Pendiente</span>;
    }
  };

  const ActionField = () => {
    const record = useRecordContext();
    if (record.is_packed) {
      return (
        <Tooltip title="Quitar de la caja">
          <IconButton
            color="warning"
            onClick={() =>
              simpleFetch({
                url: `boxes/${boxId}/package/`,
                method: 'DELETE',
                body: {
                  ohpi_code: record.id,
                },
              })
            }
          >
            <RestoreFromTrash />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Agregar a caja">
          <IconButton
            color="secondary"
            onClick={() =>
              simpleFetch({
                url: `boxes/${boxId}/package/`,
                method: 'POST',
                body: {
                  ohpi_code: record.id,
                },
              })
            }
          >
            <MoveToInbox />
          </IconButton>
        </Tooltip>
      );
    }
  };

  return (
    <List resource={`boxes/${boxId}/ingredients`} actions={false}>
      <Datagrid bulkActionButtons={false}>
        <TextFieldRA label="Ingrediente" source="prepared_ingredient.name" />
        <TextFieldRA label="Cantidad" source="ordered_quantity" />
        <TextFieldRA label="Medida" source="metric.name" />
        <StatusField label="Estado" />
        <ActionField />
      </Datagrid>
    </List>
  );
};

export default LoadBox;
