import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  useRecordContext,
} from 'react-admin';
import { Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';

import ActionsField from './ActionsField';
import { getURLParam } from '../../utils';

const PackingShow = () => {
  const navigate = useNavigate();

  const BarcodeField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return <span>{record.barcode ? 'Sí' : 'No'}</span>;
  };
  const ingredientName = getURLParam('ingredient_name');
  const ingredientId = getURLParam('ingredient_id');

  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return record.is_packed ? (
      <span className="highlight-good">Envasado</span>
    ) : (
      <span className="highlight-warn">Pendiente</span>
    );
  };

  return (
    <div className="ra-margin-top">
      <Button onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
        Volver
      </Button>
      <Typography variant="h4" textAlign="center" marginTop={2}>
        {ingredientName}
      </Typography>
      <List
        title="Packing"
        resource={'packing/ingredients'}
        filterDefaultValues={{ ingredient: ingredientId }}
        className="ra-margin-top-reset"
      >
        <Datagrid bulkActionButtons={false}>
          <TextField label="Pedido" source="id" />
          <DateField label="Fecha delivery" source="order.deliver_date" />
          <TextField label="Hora Listo" source="packing_ready_time" />
          <TextField label="Ingrediente" source="prepared_ingredient.name" />
          <TextField label="Lugar de envasado" source="packing_site.name" />
          <NumberField label="Cantidad" source="ordered_quantity" />
          <TextField label="Métrica" source="metric.name" />
          <BarcodeField label="Código" />
          <StatusField label="Estado" />
          <ActionsField />
        </Datagrid>
      </List>
    </div>
  );
};

export default PackingShow;
