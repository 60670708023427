import React from 'react';
import { Typography } from '@mui/material';
import { TourProvider } from '@reactour/tour';

const stepsText = [
  {
    selector: '.first-step',
    text: 'Bienvenido! En estos primeros pasos te mostraremos algunas cosas que puedes hacer con tu cuenta.',
  },
  {
    selector: '.second-step',
    text: 'Se le creo su nuevo sitio web que podrá ingresar desde este link.',
  },
  {
    selector: '.third-step',
    text: 'Además, tiene la posibilidad de administrar su tienda, añadiendo datos relevantes, eligiendo un color personalizado, etc.',
  },
  {
    selector: '.fourth-step',
    text: 'Ingresando aquí, puede seleccionar los medios de entrega y despacho de órdenes disponibles.',
  },
  {
    selector: '.fifth-step',
    text: 'Aquí tiene la posibilidad de agregar nuevos productos a ofrecer a sus clientes.',
  },
];

const steps = stepsText.map(({ selector, text }) => ({
  selector,
  content: () => (
    <Typography variant="body1" fontFamily={'Nexa'}>
      {text}
    </Typography>
  ),
}));

const ReactTourProvider = ({
  children,
  openSideBar,
}: {
  children: React.ReactNode;
  openSideBar: () => void;
}) => {
  return (
    <TourProvider
      steps={steps}
      showBadge={false}
      defaultOpen
      showCloseButton={true}
      disableInteraction={true}
      onClickMask={() => null}
      onClickClose={(props) => {
        props.setIsOpen(false);
        props.setCurrentStep(0);
        openSideBar();
        localStorage.setItem('tourShowed', 'true');
      }}
    >
      {children}
    </TourProvider>
  );
};

export default ReactTourProvider;
