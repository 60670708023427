import {
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
  Box,
} from '@mui/material';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  useRecordContext,
} from 'react-admin';
import { RequestQuoteOutlined, CurrencyExchange } from '@mui/icons-material';
import { Link } from 'react-router-dom';

import MoneyField from '../../components/MoneyField';
import LongField from '../../components/LongField';

const Dashboard = () => {
  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return record.is_packed ? (
      <span className="highlight-good">Envasado</span>
    ) : (
      <span className="highlight-warn">Pendiente</span>
    );
  };

  return (
    <Grid container spacing={2} sx={{ padding: 2 }} className="ra-margin-top">
      <Grid item sm={12}>
        <Typography variant="h4" gutterBottom>
          Finanzas
        </Typography>
      </Grid>
      <Grid item md={6} sm={12}>
        <Card>
          <CardContent>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                sx={{ marginBottom: 3 }}
                component={Link}
                to={`/cash_flows/create`}
                color="primary"
                variant="contained"
                endIcon={<CurrencyExchange />}
              >
                Nuevo movimiento
              </Button>
            </Box>
            <Typography variant="h6">Últimos 10 movimientos</Typography>
            <List
              className="ra-margin-top"
              resource="cash_flows"
              actions={false}
              pagination={false}
            >
              <Datagrid bulkActionButtons={false}>
                <DateField label="Vencimiento" source="due_date" />
                <TextField label="Contraparte" source="provider.name" />
                <MoneyField label="Monto" source="amount" />
                <TextField label="Movimiento" source="flow_type.name" />
                <StatusField label="Estado" />
              </Datagrid>
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} sm={12}>
        <Card>
          <CardContent>
            <Box
              m={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                sx={{ marginBottom: 3 }}
                component={Link}
                to={`/paysheets/create`}
                color="primary"
                variant="contained"
                endIcon={<RequestQuoteOutlined />}
              >
                Nuevo pago
              </Button>
            </Box>
            <Typography variant="h6">Últimos 10 pagos</Typography>
            <List
              className="ra-margin-top"
              resource="paysheets"
              actions={false}
              pagination={false}
            >
              <Datagrid bulkActionButtons={false}>
                <DateField label="Fecha" source="date" />
                <TextField label="Contraparte" source="provider.name" />
                <LongField number label="Documento" source="doc_number" />
                <MoneyField label="Monto" source="amount" />
                <StatusField label="Estado" />
              </Datagrid>
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
