interface SimpleFetchProps {
  url: string;
  method: string;
  body?: any;
  auth?: boolean;
  externalUrl?: boolean;
  headerType?: string;
}

export const simpleFetch = async ({
  url,
  method,
  body,
  auth = true,
  externalUrl = false,
  headerType,
}: SimpleFetchProps) => {
  if (
    method !== 'GET' &&
    method !== 'POST' &&
    method !== 'PUT' &&
    method !== 'PATCH' &&
    method !== 'DELETE'
  ) {
    throw new Error('Invalid HTTP method for simpleFetch function');
  }
  const headers = new Headers();
  headers.append('Content-Type', headerType || 'application/json');
  if (auth) {
    headers.append('Authorization', `Bearer ${localStorage.getItem('access')}`);
  }
  let baseUrl = '';
  if (!externalUrl) {
    baseUrl = `${process.env.REACT_APP_BACKEND_HOST}/api/v1.0/backoffice`;
  }
  return fetch(baseUrl + '/' + url + '/', {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  });
};
