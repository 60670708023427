import { useState, useEffect } from 'react';
import {
  SimpleForm,
  useEditContext,
  TextInput,
  SelectInput,
  DateInput,
  BooleanInput,
  useGetList,
  Toolbar,
  SaveButton,
  DeleteButton,
} from 'react-admin';
import {
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  TextField,
} from '@mui/material';

import MoneyInput from '../../../components/MoneyInput';
import SelectInputPaginated from '../../../components/SelectInputPaginated';

import './index.scss';

const EditForm = () => {
  const { record } = useEditContext();
  const [noDelivery, setNoDelivery] = useState(record.address.no_delivery);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([]);
  const handleNoDeliveryChange = (noDelivery: Boolean) => {
    setNoDelivery(noDelivery);
  };

  const { data: paymentMethodChoicesData } = useGetList(
    'orders/payment_method_choices',
    { pagination: { page: 1, perPage: 10 } }
  );

  // Once the data provider retrieved the data, we can use it to populate the options
  useEffect(() => {
    setPaymentMethodOptions(paymentMethodChoicesData);
  }, [paymentMethodChoicesData]);

  return (
    <SimpleForm
      id="order-edit"
      toolbar={
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <SaveButton alwaysEnable />
          <DeleteButton />
        </Toolbar>
      }
    >
      <Typography variant="h4" mb={3}>
        Detalles del pedido <b>{record.id}</b>
      </Typography>
      <Grid container spacing={5}>
        <Grid item sm={6}>
          <Typography variant="h5" className="subtitle">
            Cliente
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  {record.customer.user_name} {record.customer.last_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Teléfono</b>
                </TableCell>
                <TableCell>{record.customer.user_phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell>{record.customer.user_email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Divider />

        <Grid item sm={12}>
          <Typography variant="h5" className="subtitle">
            Despacho
          </Typography>
          <Grid container spacing={2} rowSpacing={-2} md={7} sm={12}>
            {noDelivery === false && (
              <>
                <Grid item md={6} sm={12}>
                  <TextInput label="Calle" source="address.street" fullWidth />
                </Grid>
                <Grid item md={2} sm={12}>
                  <TextInput
                    label="Número"
                    source="address.number"
                    type="number"
                  />
                </Grid>
                <Grid item md={4} sm={12}>
                  <TextInput label="Apartamento" source="address.apartment" />
                </Grid>
              </>
            )}
            <Grid item md={6} sm={12}>
              <SelectInput
                label="Tipo de entrega"
                source="address.no_delivery"
                choices={[
                  { id: false, name: 'Despacho a domicilio' },
                  { id: true, name: 'Retira en local' },
                ]}
                fullWidth
                onChange={(e) => handleNoDeliveryChange(e.target.value)}
              />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                label="Comentarios"
                source="address.comment"
                multiline
                fullWidth
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <DateInput label="Fecha de entrega" source="deliver_date" />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextField
                label="Rango de despacho"
                value={`${record.min_deliver_time} - ${record.max_deliver_time}`}
                disabled
              />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextInput label="Hora (cliente)" source="deliver_time" />
            </Grid>
            <Grid item md={3} sm={12}>
              <TextInput label="Hora armado caja" source="box_setup_time" />
            </Grid>
          </Grid>

          <Divider />

          <Typography variant="h5" className="subtitle">
            Totales
          </Typography>
          <Grid container spacing={2} rowSpacing={-2} md={7} sm={12}>
            <Grid item>
              <MoneyInput
                label="Precio de productos (inc. descuentos)"
                source="total_price"
              />
            </Grid>
            <Grid item>
              <MoneyInput label="Descuento" source="discount_ammount" />
            </Grid>
            <Grid item>
              <MoneyInput label="Precio Despacho" source="shipping_price" />
            </Grid>
            <Grid item>
              <MoneyInput label="Total" source="total_pay" disabled />
            </Grid>
          </Grid>

          <Divider />

          <Typography variant="h5" className="subtitle">
            Donación
          </Typography>
          <Grid container spacing={2} rowSpacing={-2} md={6} sm={12}>
            <Grid item>
              <SelectInputPaginated
                source="donees"
                label="Donee"
                defaultValueKey={`donee_obj`}
                sourceKey={`donee`}
              />
            </Grid>
            <Grid item>
              <MoneyInput label="Monto" source="donation" />
            </Grid>
            <Grid item>
              <BooleanInput
                label="La orden es una donación"
                source="is_donation"
              />
            </Grid>
          </Grid>

          <Divider />

          <Typography variant="h5" className="subtitle">
            Acciones
          </Typography>
          <Grid container spacing={2} rowSpacing={-2} md={8} sm={12}>
            <Grid item>
              <BooleanInput
                label="Mail de confirmación enviado"
                source="confirmation_mail_sent"
              />
            </Grid>
            <Grid item>
              <BooleanInput label="Pedido confirmado" source="is_confirmed" />
            </Grid>
            <Grid item>
              <BooleanInput
                label="Comprobante de pago emitido"
                source="proof_of_payment_emitted"
              />
            </Grid>
            <Grid item>
              <SelectInput
                label="Método de pago"
                source="payment_method"
                choices={paymentMethodOptions}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

export default EditForm;
