import React, { useEffect, useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  TextField,
  useGetList,
  List,
  Datagrid,
  DateField,
  SearchInput,
  useListContext,
  useInput,
  RaRecord,
  DatagridBody,
  Toolbar,
  SaveButton,
  useRedirect,
  useNotify,
} from 'react-admin';
import {
  Typography,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Box,
} from '@mui/material';
import MoneyField from '../../components/MoneyField';
import { moneyFormat } from '../../utils';
import { simpleFetch } from '../../utils/fetch';

const PaysheetCreate = () => {
  const [selectedCashFlows, setSelectedCashFlows] = useState<any>([]);
  const [selectedCashFlowsCost, setSelectedCashFlowsCost] = useState<any>(0);
  const [businessName, setBusinessName] = useState<number | null>(null);
  const { data: businessNames } = useGetList('business_names');
  const { data: paysheetReasons } = useGetList('paysheets/reasons');
  const { data: bankAccounts } = useGetList(
    `business_names/${businessName}/accounts`
  );
  const [bankAccountOptions, setBankAccountOptions] = useState<any>([]);
  const redirect = useRedirect();
  const notify = useNotify();

  useEffect(() => {
    if (businessNames) {
      setBusinessName(businessNames[0].id);
    }
  }, [businessNames]);

  useEffect(() => {
    if (bankAccounts) {
      setBankAccountOptions(
        bankAccounts.map((account: any) => ({
          id: account.bank.id,
          name: `${account.bank.name} - ${account.number}`,
        }))
      );
    }
  }, [bankAccounts]);

  const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput label="Vencimiento desde" source="start_due_date" alwaysOn />,
    <DateInput label="Vencimiento hasta" source="end_due_date" alwaysOn />,
  ];

  // Custom Datagrid body that reacts to changes in selected rows, this way we update the selectedCashFlows and selectedCashFlowsCost states.
  const MyDatagridBody = (props: any) => {
    const { data, selectedIds } = useListContext();
    useEffect(() => {
      setSelectedCashFlows(selectedIds);
      setSelectedCashFlowsCost(
        data.reduce((acc: number, item: RaRecord) => {
          if (selectedIds.includes(item.id)) {
            acc += Number(item.amount);
          }
          return acc;
        }, 0)
      );
    }, [data, selectedIds]);
    return <DatagridBody {...props} />;
  };

  const BusinessNameSelect = (props: any) => {
    const { field } = useInput(props);
    return (
      <Box sx={{ width: { xs: '100%', md: '30%' } }}>
        <InputLabel htmlFor="business-name-select" variant="outlined">
          {props.label}
        </InputLabel>
        <Select
          {...field}
          fullWidth
          value={businessName || ''}
          id="business-name-select"
          onChange={(e: any) => {
            setBusinessName(e.target.value);
            field.onChange(e);
          }}
        >
          {businessNames?.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    );
  };

  const handleSubmit = async (data: any) => {
    data = { ...data, cash_flows: selectedCashFlows };

    const response = await simpleFetch({
      url: 'paysheets',
      method: 'POST',
      body: data,
    });

    notify('Nómina generada con éxito');

    // Download the txt file we have in response to client
    const url = window.URL.createObjectURL(
      new Blob([await response.blob()], { type: 'text/plain' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Pago nómina.txt';
    // automatically click the link to download the file
    link.click();
    // remove the link from the DOM
    link.remove();

    redirect('/paysheets');
  };

  const defaultReason = paysheetReasons?.find(
    (reason: { id: number; name: string }) =>
      reason.name === 'Pago de proveedores'
  )?.id;

  const PaysheetsToolbar = () => (
    <Toolbar>
      <SaveButton label="Crear" />
    </Toolbar>
  );

  return (
    <div className="ra-margin-top">
      <Typography variant="h4">Generación de nómina</Typography>
      <Create resource="paysheets">
        <SimpleForm onSubmit={handleSubmit} toolbar={<PaysheetsToolbar />}>
          <Typography
            variant="body1"
            className="instructions"
            sx={{ width: { sm: 'initial', md: '30%' } }}
          >
            Seleccione los pagos que desea exportar a un archivo de texto y haga
            click en el botón "Generar" para generar una nómina.
          </Typography>

          <BusinessNameSelect source="business_name" label="Razón social" />

          <List
            className="ra-margin-top"
            resource="paysheets/cash_flows"
            exporter={false}
            filters={filters}
            actions={false}
            sx={{
              width: '100%', // TODO: why do we need a 100% width here?
              '.RaList-content': { marginTop: 5 }, // TODO Find a better way to prevent the filters being hidden by the toolbar. This margin looks ugly
            }}
          >
            <Datagrid bulkActionButtons={<></>} body={<MyDatagridBody />}>
              <DateField label="Vencimiento" source="due_date" />
              <TextField label="Contraparte" source="provider.name" />
              <TextField label="Nº documentos" source="doc_number" />
              <MoneyField source="amount" label="Monto" />
            </Datagrid>
          </List>
          <Grid container spacing={5}>
            <Grid item md={3} xs={12}>
              <SelectInput
                label="Motivo"
                source="reason"
                choices={paysheetReasons || []}
                fullWidth
                defaultValue={defaultReason}
                required
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <TextInput
                label="Descripción del pago"
                source="description"
                resettable
                multiline
                fullWidth
                required
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <SelectInput
                label="Cuenta de origen de los fondos"
                source="origin_account"
                choices={bankAccountOptions || []}
                defaultValue={bankAccountOptions?.[0]?.id}
                fullWidth
                required
              />
            </Grid>
          </Grid>
          <Typography variant="h6">
            Costo de la nómina: {moneyFormat(selectedCashFlowsCost)}
          </Typography>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default PaysheetCreate;
