import { Grid } from '@mui/material';

import logo from '../assets/logo.png';

interface AuthenticationLayoutProps {
  children: React.ReactNode;
}

const styles = {
  container: {
    minHeight: '100vh',
    backgroundColor: '#417aa0',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const AuthenticationLayout = ({ children }: AuthenticationLayoutProps) => {
  return (
    <Grid container sx={{ ...styles.container }}>
      <Grid item xs={12} mt={4} height={'68px'}>
        <img src={logo} alt="Famile logo" />
      </Grid>
      {children}
    </Grid>
  );
};

export default AuthenticationLayout;
