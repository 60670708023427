import polyglotI18nProvider from 'ra-i18n-polyglot';
import es from './i18n/es';
import en from './i18n/en';

const translations: any = { en, es };

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'es'
);
