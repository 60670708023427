import { useState } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import {
  Tooltip,
  IconButton,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Divider,
  Button,
  TextField as TextFieldMui,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  PublishedWithChangesOutlined,
  Print,
  ContentCut,
} from '@mui/icons-material';
import BaseModal from '../../components/BaseModal';
import { simpleFetch } from '../../utils/fetch';

const BorderedTD = styled(TableCell)`
  &.MuiTableCell-root {
    border: 1px solid gray;
  }
  &.MuiTableCell-head {
    font-weight: bold;
  }
`;

const ModalBody = ({
  closeModal,
  getOhpiLabel,
}: {
  getOhpiLabel: (ohpiId: number) => void;
  closeModal: () => void;
}) => {
  const [packedQuantity, setPackedQuantity] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const record = useRecordContext();

  const createDividedOhpi = async () => {
    setLoading(true);
    setError(false);
    const ohpiId = Number(record.id);

    const response = await simpleFetch({
      url: `packing/ingredients/${ohpiId}/divide`,
      method: 'POST',
      body: {
        packed_quantity: Number(packedQuantity),
      },
    });

    if (!response.ok) {
      setError(true);
    } else {
      closeModal();
      getOhpiLabel(ohpiId);
    }

    setLoading(false);
  };

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h4">Dividir en varias Bolsas</Typography>
        <Divider style={{ width: '100%' }} />
        <Typography variant="body1" marginBottom={2} marginTop={2}>
          A continuación ingresa <strong>la cantidad que fue envasada</strong>,
          y se imprimirá la etiqueta para esa cantidad. Se creará una nueva fila
          en la tabla con el resto
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <BorderedTD>Pedido</BorderedTD>
                <BorderedTD>Fecha despacho</BorderedTD>
                <BorderedTD>Hora listo</BorderedTD>
                <BorderedTD>Ingrediente</BorderedTD>
                <BorderedTD>Cantidad</BorderedTD>
                <BorderedTD>Métrica</BorderedTD>
              </TableRow>
            </TableHead>
            <TableBody>
              <BorderedTD>{record.order.id}</BorderedTD>
              <BorderedTD>{record.order.deliver_date}</BorderedTD>
              <BorderedTD>{record.packing_ready_time}</BorderedTD>
              <BorderedTD>{record.prepared_ingredient.name}</BorderedTD>
              <BorderedTD>{record.ordered_quantity}</BorderedTD>
              <BorderedTD>{record.metric.name}</BorderedTD>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        {(packedQuantity && !Number(packedQuantity)) || error ? (
          <TextFieldMui
            error
            id="tandard-error-helper-text"
            label="Cantidad empaquetada"
            variant="outlined"
            fullWidth
            helperText={
              error
                ? '¡El valor ingresado no es válido!'
                : 'El valor ingresado debe ser un número'
            }
            value={packedQuantity}
            onChange={(event) => {
              setPackedQuantity(event.target.value);
            }}
          />
        ) : (
          <TextFieldMui
            id="outlined-basic"
            label="Cantidad empaquetada"
            variant="outlined"
            fullWidth
            value={packedQuantity}
            onChange={(event) => {
              setPackedQuantity(event.target.value);
            }}
          />
        )}
        <Button
          variant="contained"
          startIcon={loading ? null : <ContentCut />}
          fullWidth
          disabled={!packedQuantity || !Number(packedQuantity)}
          onClick={() => {
            if (!loading) {
              createDividedOhpi();
            }
          }}
        >
          {loading ? <CircularProgress color="inherit" size={30} /> : 'Dividir'}
        </Button>
      </Grid>
    </Grid>
  );
};

const ActionsField = () => {
  const [openDispatchModal, setOpenDispatchModal] = useState(false);
  const record = useRecordContext();
  const refresh = useRefresh();

  const markOhpiAsPacked = async () => {
    const ohpiId = record.id;
    await simpleFetch({
      url: `packing/ingredients/${ohpiId}`,
      method: 'PATCH',
      body: {
        pending: !record.pending,
      },
    });
    refresh();
  };

  const getOhpiLabel = async (ohpiId: number) => {
    const packingPDFResponse = await simpleFetch({
      url: `packing/ingredients/${ohpiId}/label`,
      method: 'GET',
      headerType: 'application/pdf',
    });
    if (!packingPDFResponse.ok) {
      return;
    }
    //Create PDF link and open it
    const packingPDF = await packingPDFResponse.blob();
    const fileURL = window.URL.createObjectURL(packingPDF);
    window.open(fileURL, '_blank');
    refresh();
  };

  return (
    <>
      <BaseModal
        open={openDispatchModal}
        setOpen={setOpenDispatchModal}
        body={
          <ModalBody
            closeModal={() => setOpenDispatchModal(false)}
            getOhpiLabel={getOhpiLabel}
          />
        }
      />
      <div style={{ minWidth: '120px' }}>
        <Tooltip title="Cambiar estado">
          <IconButton color="secondary" onClick={markOhpiAsPacked}>
            <PublishedWithChangesOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title="Imprimir etiqueta y marcar envasado">
          <IconButton
            color="secondary"
            onClick={() => getOhpiLabel(Number(record.id))}
          >
            <Print />
          </IconButton>
        </Tooltip>
        {!record.is_packed && (
          <Tooltip title="Dividir bolsa">
            <IconButton
              color="secondary"
              onClick={() => {
                setOpenDispatchModal(true);
              }}
            >
              <ContentCut />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default ActionsField;
