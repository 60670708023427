import { useEffect, useState } from 'react';
import { Show, useShowContext } from 'react-admin';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Paper,
  Typography,
} from '@mui/material';

import Map from '../../components/Map';
import { loadMapApi } from '../../utils/googleMapsUtils';

const DeliveryShow = () => {
  const ShowBody = () => {
    const { record } = useShowContext();

    // This is needed for the map to work
    const [scriptLoaded, setScriptLoaded] = useState(false);
    useEffect(() => {
      const googleMapScript = loadMapApi();
      googleMapScript.addEventListener('load', function () {
        setScriptLoaded(true);
      });
    }, []);

    return (
      <>
        <Typography variant="h4" textAlign="center" marginTop={2}>
          {`Pedido ${record.id}`}
        </Typography>
        <Grid container spacing={10}>
          {/* Order info table */}
          <Grid item xs={6} p={2}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h2>Información de pedido</h2>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Cliente</strong>
                  </TableCell>
                  <TableCell>
                    {record.customer.user_name} {record.customer.last_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Cajas</strong>
                  </TableCell>
                  <TableCell>
                    {record.boxes ? record.boxes.length : 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Fecha</strong>
                  </TableCell>
                  <TableCell>{record.deliver_date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Hora</strong>
                  </TableCell>
                  <TableCell>{record.deliver_time}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Teléfono cliente</strong>
                  </TableCell>
                  <TableCell>{record.customer.user_phone}</TableCell>
                </TableRow>
                {/* TODO productos */}
                {/* <TableRow>
                    <TableCell>
                      <strong>Productos</strong>
                    </TableCell>
                    <TableCell>-----</TableCell>
                  </TableRow> */}
              </TableBody>
            </Table>
          </Grid>

          {/* Delivery info table */}
          <Grid item xs={6} p={2}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <h2>Información de despacho</h2>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Grupo de despacho</strong>
                  </TableCell>
                  <TableCell>
                    {record.delivery.delivery_group.delivery_guy.username}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Estado</strong>
                  </TableCell>
                  <TableCell>
                    <span className="highlight-good">{record.status}</span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Comuna</strong>
                  </TableCell>
                  <TableCell>{record.address.commune.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Dirección</strong>
                  </TableCell>
                  <TableCell>
                    {record.address.street} {record.address.number}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Apartamento</strong>
                  </TableCell>
                  <TableCell>{record.address.apartment}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Tiempo estimado de viaje</strong>
                  </TableCell>
                  <TableCell>{record.address.travel_time}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        {scriptLoaded && (
          <Map
            initLat={-34.914088}
            initLng={-56.174078}
            mapType={google.maps.MapTypeId.ROADMAP}
          />
        )}
      </>
    );
  };

  return (
    <Show resource="deliveries" className="ra-margin-top">
      <ShowBody />
    </Show>
  );
};

export default DeliveryShow;
