import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type tab = { label: string; body: JSX.Element; index: number };

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsLayout = ({ tabs }: { tabs: Array<tab> }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    // When a tab is selected, we set a URL param with the tab index. So when the user clicks the back button, it comes back to the same tab that was selected.
    window.history.pushState(
      { additionalInformation: 'Updated the URL with JS' },
      '',
      `?tab=${newValue}`
    );
  };

  React.useEffect(() => {
    // If in URL a tab index is set, we select that tab
    const url = new URL(window.location.href);
    const tab = url.searchParams.get('tab');
    if (tab) {
      setValue(Number(tab));
    }
  }, []);

  return (
    <Box sx={{ width: '100%' }} className="ra-margin-top">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((tab) => (
            <Tab label={tab.label} {...a11yProps(tab.index)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab) => (
        <TabPanel value={value} index={tab.index}>
          {tab.body}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabsLayout;
