import { FormEvent, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Box,
  Stack,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';

import { simpleFetch } from '../../utils/fetch';

const ProvidersPurchases = () => {
  const [startDate, setStartDate] = useState<String>();
  const [endDate, setEndDate] = useState<String>();
  const [loading, setLoading] = useState<Boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const response = await simpleFetch({
      url: 'providers/orders/export',
      method: 'POST',
      auth: true,
      body: { start_date: startDate, end_date: endDate },
    });
    const blob = await response.blob();
    const contentTypeHeader = response.headers.get('content-type') as string;
    const downloadLink = window.document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(
      new Blob([blob], {
        type: contentTypeHeader,
      })
    );
    downloadLink.download = `Reporte de compras a proveedores entre ${startDate} y ${endDate}.xls`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setLoading(false);
  };

  return (
    <Container className="ra-margin-top">
      <Typography variant="h4" textAlign="center" m={4}>
        Exportar compras a proveedores a Excel
      </Typography>
      <Paper>
        <Typography align="center" pt={4}>
          Seleccione rango de fechas para el reporte
        </Typography>
        <Box component="form" p={4} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack>
                <Typography component="label">Desde</Typography>
                <TextField
                  hiddenLabel
                  variant="outlined"
                  type="date"
                  required
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <Typography component="label">Hasta</Typography>
                <TextField
                  hiddenLabel
                  variant="outlined"
                  type="date"
                  required
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item sm={12} textAlign="right">
              {loading ? (
                <CircularProgress size={30} sx={{ marginRight: 2 }} />
              ) : (
                <Button type="submit">Descargar reporte</Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProvidersPurchases;
