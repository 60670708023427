import { useEffect } from 'react';
import { Login, useTranslate } from 'react-admin';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { simpleFetch } from '../../utils/fetch';
import { Link } from '@mui/material';

const MyLoginPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const translate = useTranslate();
  const handleTokenLogin = async (access: string) => {
    // Send a token to backend and return a new access and refresh to set in ls.
    // This way the user is logged into the page.
    const resp = await simpleFetch({
      url: `token_login`,
      method: 'POST',
      auth: false,
      body: {
        access: access,
      },
    });
    if (resp.ok) {
      const data = await resp.json();
      localStorage.setItem('access', data.access);
      localStorage.setItem('refresh', data.refresh);
      navigate('/');
    }
  };

  useEffect(() => {
    localStorage.removeItem('tourShowed');
    // If we have 'access' as a query param then we try to perform a login with that token.
    if (searchParams.get('access')) {
      handleTokenLogin(searchParams.get('access') || '');
    }
  }, []);

  return (
    <div id="login">
      <Login sx={{ backgroundImage: 'none', minHeight: '28rem' }} />
      <Link onClick={() => navigate('/signup')} style={{ cursor: 'pointer' }}>
        {translate('signup.not_registered')}
      </Link>
    </div>
  );
};

export default MyLoginPage;
