import { Sidebar } from 'react-admin';

const MySidebar = (props: any) => (
  <Sidebar
    sx={{
      '& .RaSidebar-drawerPaper': {
        backgroundColor: 'red',
      },
    }}
    {...props}
  />
);

export default MySidebar;
