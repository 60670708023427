import { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  useRecordContext,
  DateInput,
  SelectInput,
  SearchInput,
  useGetList,
} from 'react-admin';

import MoneyField from '../../components/MoneyField';
import LongField from '../../components/LongField';
import CustomPagination from '../../components/CustomPagination';

const CashFlow = () => {
  const [businessNamesOptions, setBusinessNamesOptions] = useState<any>([]);

  const { data: businessNames } = useGetList('business_names');

  useEffect(() => {
    setBusinessNamesOptions(
      businessNames
        ? businessNames.map((businessName) => ({
            id: businessName.id,
            name: businessName.name,
          }))
        : []
    );
  }, [businessNames]);

  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <p>
        {record.is_canceled ? (
          <span className="highlight-warn">Cancelado</span>
        ) : (
          <span className="highlight-good">No cancelado</span>
        )}
      </p>
    );
  };

  const cashFlowFilters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput label="Vencimiento desde" source="start_due_date" alwaysOn />,
    <DateInput label="Vencimiento hasta" source="end_due_date" alwaysOn />,
    <SelectInput
      source="canceled"
      label="Estado"
      choices={[
        { id: true, name: 'Cancelado' },
        { id: false, name: 'No cancelado' },
      ]}
      alwaysOn
    />,
    <SelectInput
      source="flow_type"
      label="Tipo"
      choices={[
        { id: 0, name: 'Egreso' },
        { id: 1, name: 'Ingreso' },
      ]}
      alwaysOn
    />,
    <SelectInput
      label="Razón Social"
      source="business_name"
      choices={businessNamesOptions}
      alwaysOn
    />,
  ];

  return (
    <List
      className="ra-margin-top"
      resource="cash_flows"
      hasCreate
      filters={cashFlowFilters}
      exporter={false}
      pagination={<CustomPagination />}
    >
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <DateField label="Vencimiento" source="due_date" />
        <TextField label="Contraparte" source="provider.name" />
        <LongField number label="Número de documento" source="doc_number" />
        <MoneyField label="Monto" source="amount" />
        <TextField label="Movimiento" source="flow_type.name" />
        <LongField label="Comentarios" source="comments" />
        <StatusField label="Estado" />
      </Datagrid>
    </List>
  );
};

export default CashFlow;
