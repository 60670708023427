import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSidebarState } from 'react-admin';
import { Grid, CircularProgress } from '@mui/material';
import Charts from './Charts';
import { simpleFetch } from '../../utils/fetch';

const Dashboard = () => {
  const [userOutlet, setUserOutlet] = useState<any>(null);
  const [, setOpen] = useSidebarState();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserOutlet = async () => {
      const resp = await simpleFetch({
        url: `user_outlets/first`,
        method: 'GET',
        auth: true,
      });
      const data = await resp.json();
      setUserOutlet(data);
    };

    getUserOutlet();
  }, []);

  useEffect(() => {
    if (userOutlet) {
      const newCustomer =
        !userOutlet.contact_phone ||
        !userOutlet.address ||
        userOutlet.contact_phone === 'noingresado' ||
        userOutlet.address === 'noingresado';

      const tourShowed = localStorage.getItem('tourShowed');

      if (newCustomer && !tourShowed) {
        setOpen(false);
        navigate('/ecommerce');
      }
    }
  }, [userOutlet, navigate, setOpen]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ padding: 2, justifyContent: 'center' }}
      className="ra-margin-top first-step"
    >
      {userOutlet ? (
        <Charts />
      ) : (
        <CircularProgress size={80} sx={{ justifyContent: 'center' }} />
      )}
    </Grid>
  );
};

export default Dashboard;
