import { useState } from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  TextField,
  useDataProvider,
  useRecordContext,
  SearchInput,
  DateInput,
} from 'react-admin';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { useMutation } from 'react-query';

const Packing = () => {
  const PackerField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    const [packer, setPacker] = useState(record.packing_profile);
    const dataProvider = useDataProvider();
    const { mutate } = useMutation(
      [
        `packing_${record.id}`,
        'update',
        { id: record.id, data: { packing_profile: packer.id } },
      ],
      () =>
        dataProvider.update(`packing/ingredients`, {
          id: record.id,
          data: { packing_profile: packer.id },
          previousData: undefined,
        })
    );
    const handleChange = (event: SelectChangeEvent) => {
      mutate();
      setPacker(event.target.value as string);
    };
    return (
      <Select value={packer} onChange={handleChange} className="datagrid-input">
        {record.packing_profiles_available.map((packer: any) => (
          <MenuItem value={packer.id}>{packer.user.username}</MenuItem>
        ))}
      </Select>
    );
  };

  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return record.status ? (
      <span className="highlight-good">Envasado</span>
    ) : (
      <span className="highlight-warn">Pendiente</span>
    );
  };

  const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput label="Despacho desde" source="start_date" alwaysOn />,
    <DateInput label="Despacho hasta" source="end_date" alwaysOn />,
  ];

  return (
    <List
      title="Packing"
      resource="packing/ingredients"
      filters={filters}
      actions={false}
      className="ra-margin-top"
    >
      <Datagrid bulkActionButtons={false}>
        <TextField label="Pedido" source="id" />
        <DateField label="Fecha delivery" source="order.deliver_date" />
        <TextField label="Hora Listo" source="packing_ready_time" />
        <TextField label="Ingrediente" source="prepared_ingredient.name" />
        <TextField label="Lugar de envasado" source="packing_site.name" />
        <NumberField label="Cantidad" source="ordered_quantity" />
        <TextField label="Métrica" source="metric.name" />
        <StatusField label="Estado" />
        <PackerField label="Envasador" />
      </Datagrid>
    </List>
  );
};

export default Packing;
