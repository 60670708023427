import raLanguageEnglish from 'ra-language-english';

const es = {
  ...raLanguageEnglish,
  famile: {
    my_checking_account: 'Checking account',
    my_transactions: 'My transactions',
  },
  menu: {
    management: {
      a: 'Management',
      cash_flows: 'Cash flow',
      paysheets: 'Paysheets',
    },
    orders: 'Orders',
    orders_management: {
      a: 'Orders management',
      list: 'Orders',
      box_assembling: 'Box assembling',
      deliveries: 'Deliveries',
      pickup: 'Pickup',
    },
    packing: {
      a: 'Packing',
      list: 'Envasado',
      management: 'Management',
      transfers: 'Transfers',
    },
    providers_purchases: 'Purchases',
  },
};

export default es;
