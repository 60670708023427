import {
  Datagrid,
  DateField,
  NumberField,
  List,
  useRecordContext,
  BulkDeleteButton,
  SearchInput,
  DateInput,
} from 'react-admin';
import CustomPagination from '../../components/CustomPagination';

const OrderList = () => {
  const CustomerNameField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <span>{`${record.customer.user_name} ${record.customer.last_name}`}</span>
    );
  };

  const DeliveryTimeRangeField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    return (
      <span>
        {record.min_deliver_time} - {record.max_deliver_time}
      </span>
    );
  };

  const PaymentStatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    if (record.is_paid) {
      return <span className="highlight-good">Pagado</span>;
    } else {
      return <span className="highlight-warn">Pendiente</span>;
    }
  };

  const filters = [
    <SearchInput source="q" alwaysOn />,
    <DateInput label="Fecha despacho" source="date" alwaysOn />,
    <DateInput label="Despacho desde" source="start_date" alwaysOn />,
    <DateInput label="Despacho hasta" source="end_date" alwaysOn />,
  ];

  return (
    <List
      resource="orders"
      filters={filters}
      actions={false}
      className="ra-margin-top"
      pagination={<CustomPagination />}
    >
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
      >
        <NumberField label="Pedido" source="id" sx={{ fontWeight: 'bold' }} />
        <CustomerNameField label="Cliente" />
        <DateField label="Fecha de despacho" source="deliver_date" />
        <DeliveryTimeRangeField label="Rango horario" />
        <PaymentStatusField label="Estado del pago" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
