import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Button, CardContent, CircularProgress } from '@mui/material';
import {
  Form,
  required,
  useTranslate,
  useNotify,
  useSafeSetState,
} from 'ra-core';
import { TextInput } from 'react-admin';
import { simpleFetch } from '../utils/fetch';

export const SignupForm = (props: SignupFormProps) => {
  const { className } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();

  const submit = async (values: any) => {
    setLoading(true);
    simpleFetch({
      url: `users`,
      method: 'POST',
      auth: false,
      body: {
        ...values,
        redirect_url: `${process.env.REACT_APP_BASE_REDIRECT_URL}`,
      },
    })
      .then((resp) => {
        setLoading(false);
        if (resp.status === 200 || resp.status === 201) {
          props.setSuccessSignup(true);
        } else {
          notify('Error en el registro, revise los campos del formulario.', {
            type: 'warning',
            messageArgs: 'Error',
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        notify('Error en el registro', {
          type: 'warning',
          messageArgs: 'Error',
        });
      });
  };

  return (
    <StyledForm
      onSubmit={submit}
      mode="onChange"
      noValidate
      className={className}
    >
      <CardContent className={SignupFormClasses.content}>
        <TextInput
          source="first_name"
          label={translate('signup.first_name')}
          validate={required()}
          fullWidth
          className={SignupFormClasses.input}
        />
        <TextInput
          source="last_name"
          label={translate('signup.last_name')}
          validate={required()}
          fullWidth
          className={SignupFormClasses.input}
        />
        <TextInput
          source="email"
          label={translate('signup.email')}
          validate={required()}
          fullWidth
          className={SignupFormClasses.input}
        />
        <TextInput
          source="confirm_email"
          label={translate('signup.confirm_email')}
          validate={required()}
          fullWidth
          className={SignupFormClasses.input}
        />
        <TextInput
          source="password"
          label={translate('ra.auth.password')}
          type="password"
          validate={required()}
          fullWidth
          className={SignupFormClasses.input}
        />
        <TextInput
          source="confirm_password"
          label={translate('signup.confirm_password')}
          type="password"
          validate={required()}
          fullWidth
          className={SignupFormClasses.input}
        />

        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
          className={SignupFormClasses.button}
        >
          {loading ? (
            <CircularProgress
              className={SignupFormClasses.icon}
              size={19}
              thickness={3}
            />
          ) : (
            translate('signup.create_user')
          )}
        </Button>
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = 'RaLoginForm';

export const SignupFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
  input: `${PREFIX}-input`,
};

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${SignupFormClasses.content}`]: {
    width: 300,
    margin: 0,
  },
  [`& .${SignupFormClasses.button}`]: {
    margin: 0,
  },
  [`& .${SignupFormClasses.input}`]: {
    margin: 0,
  },
  [`& .${SignupFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface SignupFormProps {
  redirectTo?: string;
  className?: string;
  setSuccessSignup: any;
}

SignupForm.propTypes = {
  redirectTo: PropTypes.string,
};
