import { useState, useEffect } from 'react';
import { useSidebarState } from 'react-admin';
import { Grid } from '@mui/material';
import OutletConfiguration from './OutletConfiguration';
import { simpleFetch } from '../../utils/fetch';

const Ecommerce = () => {
  const [userOutlet, setUserOutlet] = useState<any>(null);
  const [showTour, setShowTour] = useState<Boolean>(false);
  const [, setOpen] = useSidebarState();

  useEffect(() => {
    const getUserOutlet = async () => {
      const resp = await simpleFetch({
        url: `user_outlets/first`,
        method: 'GET',
        auth: true,
      });
      const data = await resp.json();
      setUserOutlet(data);
    };

    getUserOutlet();
  }, []);

  // *** Uncomment next code when the tour needs to be showed again ***

  // const outletBasicData =
  //   userOutlet &&
  //   userOutlet.contact_phone &&
  //   userOutlet.address &&
  //   userOutlet.contact_phone !== 'noingresado' &&
  //   userOutlet.address !== 'noingresado';

  // const tourShowed = localStorage.getItem('tourShowed');

  // useEffect(() => {
  //   const setTour = !tourShowed && userOutlet && !outletBasicData;
  //   if (setTour) {
  //     //New user
  //     setShowTour(true);
  //   }
  // }, [setOpen, tourShowed, outletBasicData, userOutlet]);

  return (
    <Grid container spacing={2} className="ra-margin-top first-step">
      {userOutlet && (
        <OutletConfiguration userOutlet={userOutlet} showTour={showTour} />
      )}
    </Grid>
  );
};

export default Ecommerce;
