import { useRecordContext } from 'react-admin';
import { Tooltip } from '@mui/material';

type LongFieldProps = {
  source: string;
  number?: boolean;
  label?: string;
  sx?: any;
};

// For displaying fields in datagrid which resources are very long values
const LongField = (props: LongFieldProps) => {
  const record = useRecordContext();

  const enShortText = (text: string) => {
    if (text.length > 15) {
      return text.substring(0, 15) + '...';
    }
    return text;
  };

  return (
    <Tooltip title={record[props.source]} placement="top">
      <span style={{ float: props.number ? 'right' : 'left' }}>
        {enShortText(record[props.source])}
      </span>
    </Tooltip>
  );
};

export default LongField;
