import { useState } from 'react';
import { Grid, Button, Typography, Box } from '@mui/material';
import { Signup } from '../../components/Signup';
import signupImg from '../../assets/Signup/Signup1.png';

const styles = {
  container: {
    color: 'white',
    padding: { md: '0 25%', xs: '0 10px' },
  },
  firstParagraph: {
    borderTop: '4px solid white ',
    borderBottom: '2px solid white ',
    padding: '1rem 0',
  },
  secondParagraph: {
    borderBottom: '4px solid white ',
    padding: '1rem 0',
  },
  image: { width: { md: '15rem', xs: '80%' } },
  button: {
    marginTop: '1rem',
    marginBottom: '2rem',
  },
};

const MySignupPage = () => {
  const [firstPage, setFirstPage] = useState(true);

  return (
    <div id="signup">
      {firstPage ? (
        <Grid
          container
          sx={{
            color: 'white',
          }}
        >
          <Grid item xs={12} mt={4} sx={{ ...styles.container }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              ¿Quieres Digitalizar tu Negocio y no tienes la tecnología?
            </Typography>
            <Box
              component="img"
              sx={{ ...styles.image }}
              src={signupImg}
              alt="Famile signup img"
            ></Box>
            <Typography variant="h5" sx={{ ...styles.firstParagraph }}>
              Famil-e te entrega una plataforma para administrar tus facturas,
              automatizar tus pagos y vender directamente en tu tienda.
            </Typography>
            <Typography variant="h5" sx={{ ...styles.secondParagraph }}>
              Además, te ofrece planes avanzados para organizar tu picking,
              packing, delivery, fidelización ¡y MÁS!.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ ...styles.button }}
              onClick={() => {
                setFirstPage(false);
              }}
            >
              Registrarse
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Signup />
      )}
    </div>
  );
};

export default MySignupPage;
