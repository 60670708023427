import {
  Datagrid,
  DateField,
  TextField,
  NumberField,
  List,
  useRecordContext,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const Boxes = () => {
  const LoadBoaxButtonField = () => {
    const navigate = useNavigate();

    const record = useRecordContext();
    return (
      <>
        <Button
          sx={{ marginLeft: 2 }}
          variant="outlined"
          onClick={() => navigate(`${record.id}`)}
          className="datagrid-button"
        >
          Armar
        </Button>
      </>
    );
  };

  const StatusField = ({ label }: { label: string }) => {
    const record = useRecordContext();
    if (record.status) {
      return <span className="highlight-good">Listo</span>;
    } else {
      return <span className="highlight-warn">Pendiente</span>;
    }
  };

  return (
    <List resource="boxes">
      <Datagrid bulkActionButtons={false}>
        <TextField label="Pedido" source="id" sx={{ fontWeight: 'bold' }} />
        <DateField label="Fecha delivery" source="deliver_date" />
        <TextField label="Hora delivery" source="max_deliver_time" />
        <NumberField label="Cajas" source="boxes.length" />
        <StatusField label="Estado" />
        <LoadBoaxButtonField />
      </Datagrid>
    </List>
  );
};

export default Boxes;
