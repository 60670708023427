import { Chart } from 'react-google-charts';

export const data = [
  ['Año', 'Ventas', 'Gastos', 'Ganancia'],
  ['2021', 0, 0, 0],
  ['2022', 0, 0, 0],
  ['2023', 0, 0, 0],
];

export const options = {
  title: 'Performance de la empresa',
  orientation: 'horizontal',
};

export default function Ch() {
  return (
    <Chart chartType="BarChart" height="300px" data={data} options={options} />
  );
}
