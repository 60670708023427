import { useEffect, useState, useCallback } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { simpleFetch } from '../utils/fetch';

type AutocompleteCustomProps = {
  label: string;
  source: string;
  onChange: (props: any) => void;
};

const AutocompleteCustom = ({
  label,
  source,
  onChange,
}: AutocompleteCustomProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState(false);

  const getOptions = useCallback(
    async (value: string) => {
      setLoading(true);
      const response = await simpleFetch({
        url: `${source}/?q=${value}`,
        method: 'GET',
      });
      if (response.ok) {
        const data = await response.json();
        setOptions(data.results);
      }

      setLoading(false);
    },
    [source]
  );

  useEffect(() => {
    getOptions('');
  }, [getOptions]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      open={open}
      onChange={(_, val) => {
        onChange(val);
      }}
      onInputChange={(_, val) => {
        getOptions(val);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      noOptionsText="No encontrado"
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteCustom;
