import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route, BrowserRouter } from 'react-router-dom';
import 'moment/locale/es';
import drfProvider, {
  jwtTokenAuthProvider,
  fetchJsonWithAuthJWTToken,
} from 'ra-data-django-rest-framework';

import './theming/styles.scss';

import { i18nProvider } from './i18nProvider';
import { lightTheme } from './theming/theme';
import MyLayout from './containers/MyLayout';
import Dashboard from './containers/Dashboard';
import OrderList from './containers/Orders/OrderList';
import OrderEdit from './containers/Orders/OrderEdit';
import Ecommerce from './containers/Ecommerce';
import Boxes from './containers/Boxes';
import LoadBox from './containers/Boxes/LoadBox';
import Delivery from './containers/Delivery';
import LoadTruck from './containers/Delivery/LoadTruck';
import Pickup from './containers/Pickup';
import ProvidersPurchases from './containers/ProvidersPurchases';
import CashFlow from './containers/CashFlow';
import CashFlowEdit from './containers/CashFlow/CashFlowEdit';
import Paysheets from './containers/Paysheets';
import PaysheetCreate from './containers/Paysheets/PaysheetCreate';
import DeliveryShow from './containers/Delivery/DeliveryShow';
import FlowCreate from './containers/CashFlow/FlowCreate';
import FinancesDashboard from './containers/FinancesDashboard';
import PackingList from './containers/PackingList';
import PackingManagement from './containers/PackingManagement';
import PackingTransfer from './containers/PackingTransfer';
import PackingShow from './containers/PackingList/PackingShow';
import { resetFilters } from './utils';
import { useEffect } from 'react';
import MySignupPage from './containers/MyLayout/MySignup';
import MyLoginPage from './containers/MyLayout/MyLogin';
import MyCreateCompany from './containers/MyLayout/MyCreateCompany';
import AuthenticationLayout from './components/AuthenticationLayout';

const authProvider = jwtTokenAuthProvider({
  obtainAuthTokenUrl: `${process.env.REACT_APP_BACKEND_HOST}/api/v1.0/backoffice/login/`,
});
const dataProvider = drfProvider(
  `${process.env.REACT_APP_BACKEND_HOST}/api/v1.0/backoffice`,
  fetchJsonWithAuthJWTToken
);

// If we're in production we don't want the console to show anything in the browser
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
}

const App = () => {
  useEffect(() => {
    // This is used to return filters to their default value when closing the broswer.
    // We need to do this because react admin persist filters in localStorage and Manu doesn't want that.
    window.addEventListener('beforeunload', resetFilters);

    return () => {
      window.removeEventListener('beforeunload', resetFilters);
    };
  }, []);

  return (
    <BrowserRouter>
      <Admin
        dataProvider={dataProvider}
        layout={MyLayout}
        theme={lightTheme}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        loginPage={
          <AuthenticationLayout>
            <MyLoginPage />
          </AuthenticationLayout>
        }
      >
        <Resource
          name="orders"
          list={OrderList}
          options={{ label: 'Pedidos' }}
        />

        {/* For custom menu items*/}
        <CustomRoutes>
          <Route path="/dashboard" element={<Dashboard />} />
          {/* Orders list */}
          <Route path="/orders" element={<OrderList />} />
          <Route path="/orders/:id" element={<OrderEdit />} />
          {/* Boxes */}
          <Route path="/boxes" element={<Boxes />} />
          <Route path="/boxes/:id" element={<LoadBox />} />
          {/* Delivery */}
          <Route path="/deliveries" element={<Delivery />} />
          <Route path="/deliveries/:id/show" element={<DeliveryShow />} />
          <Route path="/deliveries/:id/load_truck" element={<LoadTruck />} />
          {/* Delivery */}
          <Route path="/ecommerce" element={<Ecommerce />} />
          {/* Pickup */}
          <Route path="/pickup" element={<Pickup />} />
          {/* Packing */}
          <Route path="/packing" element={<PackingList />} />
          <Route
            path="/packing/ingredients/count/:id/show"
            element={<PackingShow />}
          />
          <Route path="/packing/management" element={<PackingManagement />} />
          <Route path="/packing/transfer" element={<PackingTransfer />} />"
          {/* Purchases to providers */}
          <Route path="/providers_purchases" element={<ProvidersPurchases />} />
          {/* Cash flow */}
          <Route path="/cash_flows" element={<CashFlow />} />
          <Route path="/cash_flows/create" element={<FlowCreate />} />
          <Route path="/cash_flows/:id" element={<CashFlowEdit />} />
          {/* Paysheets */}
          <Route path="/paysheets" element={<Paysheets />} />
          <Route path="/paysheets/create" element={<PaysheetCreate />} />
          {/* Finances dashboard */}
          <Route path="/finances/dashboard" element={<FinancesDashboard />} />
        </CustomRoutes>
        {/* We need to use a separate custom route to use noLayout prop and be able to access routes without authenticate */}
        {/* https://marmelab.com/react-admin/CustomRoutes.html */}
        <CustomRoutes noLayout>
          <Route
            path="/signup"
            element={
              <AuthenticationLayout>
                <MySignupPage />
              </AuthenticationLayout>
            }
          />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route
            path="/create_company"
            element={
              <AuthenticationLayout>
                <MyCreateCompany />
              </AuthenticationLayout>
            }
          />
        </CustomRoutes>
      </Admin>
    </BrowserRouter>
  );
};

export default App;
