import { createTheme } from '@mui/material';
import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';

const primary = '#88b724';
const secondary = '#417aa0';

const lightTheme = createTheme(
  merge({}, defaultTheme, {
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
    },
    components: {
      // Name of the component
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
      },
      // Menu item
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            // invisible border when not active, to avoid position flashs
            borderLeft: '3px solid transparent',
            '&.RaMenuItemLink-active': {
              borderLeft: `2px solid ${secondary}`,
            },
            width: '500px',
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
        },
      },
      RaDatagrid: {
        styleOverrides: {
          root: {
            overflow: 'scroll',
            '@media (max-width: 600px)': {
              width: '95vw',
            },
          },
        },
      },
    },
    typography: {
      fontFamily: [
        'Nexa',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  })
);

const darkTheme = createTheme({
  palette: { mode: 'dark' },
});

export { lightTheme, darkTheme };
