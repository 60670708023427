import { useState } from 'react';
import { Card, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { SignupForm } from './SignupForm';

const SuccessSignup = () => {
  return (
    <div style={{ margin: '1.25rem' }}>
      <Typography variant="h4">El usuario se creo correctamente</Typography>
      <Typography component="p">
        Active la cuenta desde el link que le enviamos al correo electrónico
        usado para registrarse.
      </Typography>
    </div>
  );
};

export const Signup = () => {
  const translate = useTranslate();
  const [successSignup, setSuccessSignup] = useState(false);
  const navigate = useNavigate();

  return (
    <Root>
      <Card className={LoginClasses.card}>
        <div className={LoginClasses.avatar}>
          {successSignup ? (
            <Typography variant="h5">Usuario creado con éxito!</Typography>
          ) : (
            <Typography variant="h5">Registra tu empresa</Typography>
          )}
        </div>
        {successSignup ? (
          <SuccessSignup />
        ) : (
          <SignupForm setSuccessSignup={setSuccessSignup} />
        )}
      </Card>

      <Link onClick={() => navigate('/login')} style={{ cursor: 'pointer' }}>
        {translate('signup.already_signup')}
      </Link>
    </Root>
  );
};

const PREFIX = 'RaLogin';
export const LoginClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
};

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [`& .${LoginClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
    overflow: 'scroll',
  },
  [`& .${LoginClasses.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${LoginClasses.icon}`]: {
    backgroundColor: 'blue',
  },
}));
